import { Button, Container, Heading, SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "lib/context";

const financePages: {
  name: string;
  path: string;
  permission: string;
  isDisabled?: boolean;
}[] = [
  {
    name: "Products",
    path: "/subscriptions/products",
    permission: "read:subscriptions",
  },
  {
    name: "Invoices",
    path: "/subscriptions/invoices",
    permission: "read:business",
  },
  {
    name: "Coupons",
    path: "/subscriptions/coupons",
    permission: "read:subscriptions",
  },
  {
    name: "Subscribers",
    path: "/subscriptions/subscribers",
    permission: "read:subscriptions",
    isDisabled: true,
  },
  {
    name: "Subscriptions",
    path: "/subscriptions",
    permission: "read:subscriptions",
  },
  {
    name: "Payments",
    path: "/subscriptions/payments",
    permission: "read:subscriptions",
  },
  {
    name: "Payouts",
    path: "/stripe/payouts",
    permission: "read:business",
  },
  {
    name: "Operation costs",
    path: "/business/costs",
    permission: "read:business",
  },
];

const Finances = () => {
  const { permissions } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Container maxW="container.xl" centerContent>
      <Heading>Data related to Stripe and subscriptions</Heading>
      <SimpleGrid mt={5} columns={2} spacing={3}>
        {financePages
          .filter(
            (page) =>
              !(page.isDisabled || !permissions.includes(page.permission))
          )
          .map((page) => (
            <Button
              key={page.name}
              onClick={() => {
                navigate(page.path);
              }}
            >
              {page.name}
            </Button>
          ))}
      </SimpleGrid>
    </Container>
  );
};

export default Finances;
