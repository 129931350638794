import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { type FC, useState } from "react";
import { useParams } from "react-router-dom";
import MessageSection from "components/Messages/MessageSection";
import AuthPageGetData from "components/common/AuthPageGetData";
import WriteMessage from "components/Messages/WriteMessage";
import { appConfig } from "config/constants";
import { useAuth0 } from "@auth0/auth0-react";

const Messages: FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { chatId } = useParams();
  const [chatData, setChatData] = useState<any>(null);
  const [inputMessage, setInputMessage] = useState<string>("");

  const handleSendMessage = async () => {
    if (!inputMessage.trim().length) {
      return;
    }
    const messageText = inputMessage;
    setChatData((prev: any) => ({
      messages: [
        ...prev.messages,
        {
          fromClient: false,
          message: messageText,
          messageSentTime: new Date().getTime(),
          type: "text",
        },
      ],
    }));
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: "DC-AD-API",
          scope: "write:chat",
        },
      });
      await fetch(appConfig.API_BASE_URL + "/chat/" + chatId + "/send", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fromClient: false,
          message: messageText,
          messageSentTime: new Date().getTime(),
          type: "text",
        }),
      });
    } catch (e) {
      console.error(e);
    }
    setInputMessage("");
  };

  if (!chatData) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/chat/" + chatId + "/messages"}
        authScope="read:chat"
        pageData={chatData}
        setData={setChatData}
      />
    );
  }

  return (
    <Box>
      <Heading mb={5}>
        Chat with{" "}
        {chatData.userData
          ? chatData.userData.displayName
            ? chatData.userData.displayName +
              " (" +
              chatData.userData.username +
              ")"
            : chatData.userData.username
            ? chatData.userData.username
            : "anonymous (registration not finished)"
          : "anonymous (unregistered)"}
      </Heading>
      {!chatData.userData?.username && (
        <Text mb={5}>email: {chatData.messages[0].email}</Text>
      )}
      <Text>Problem category: {chatData.messages[0].problem}</Text>
      {chatData.userData && (
        <Text>
          {chatData.userData.subscriptionLevel
            ? "subscription level: " + chatData.userData.subscriptionLevel
            : ""}
        </Text>
      )}
      <Flex w="100%" h="100vh" justify="center" align="center">
        <Flex w={["100%", "100%", "80%"]} h="90%" flexDir="column">
          <Divider w="100%" borderBottomWidth="3px" color="black" mt="5" />
          <MessageSection chatData={chatData} />
          <Divider w="100%" borderBottomWidth="3px" color="black" mt="5" />
          <WriteMessage
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Messages;
