import { useAuth0 } from "@auth0/auth0-react";
import { Button, MenuItem } from "@chakra-ui/react";

const LoginButton = ({
  buttonText,
  menuItem,
}: {
  buttonText: string;
  menuItem: boolean;
}) => {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    await loginWithRedirect({
      authorizationParams: {
        prompt: "login",
      },
      appState: {
        returnTo: window.location.pathname,
      },
    });
  };
  if (menuItem) {
    return <MenuItem onClick={handleLogin}>{buttonText}</MenuItem>;
  }
  return <Button onClick={handleLogin}>{buttonText}</Button>;
};

const LogoutButton = ({
  buttonText,
  menuItem,
}: {
  buttonText: string;
  menuItem: boolean;
}) => {
  const { logout } = useAuth0();
  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  if (menuItem) {
    return <MenuItem onClick={handleLogout}>{buttonText}</MenuItem>;
  }
  return <Button onClick={handleLogout}>{buttonText}</Button>;
};

const AuthenticationButton = ({ menuItem = true }: { menuItem?: boolean }) => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <LogoutButton buttonText="Sign out" menuItem={menuItem} />
  ) : (
    <LoginButton buttonText="Sign in" menuItem={menuItem} />
  );
};

export default AuthenticationButton;
