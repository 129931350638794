import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Button,
  Container,
  Heading,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { AuthContext } from "lib/context";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const { roles } = useContext(AuthContext);
  const { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState<any>(null);

  if (!invoiceData) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/finances/stripe/invoices/" + invoiceId}
        authScope="read:business"
        pageData={invoiceData}
        setData={setInvoiceData}
      />
    );
  }

  return (
    <Container>
      <Heading as="h1" size="lg">
        Invoice {invoiceData.number}
        {roles.includes("Stripe") && (
          <IconButton
            onClick={() =>
              window.open(
                "https://dashboard.stripe.com" +
                  (process.env.NODE_ENV === "development" ? "/test" : "") +
                  "/invoices/" +
                  invoiceData.id,
                "_blank",
                "noreferrer"
              )
            }
            ml={2}
            aria-label={invoiceData.number + "on the Stripe dashboard"}
            icon={<ExternalLinkIcon />}
          />
        )}
      </Heading>
      <VStack mt={10}>
        <Text>Status: {invoiceData.status}</Text>
        <Text>
          Amount due: {invoiceData.amount_due / 100}{" "}
          {invoiceData.currency.toUpperCase()}
        </Text>
        <Text>
          Amount paid: {invoiceData.amount_paid / 100}{" "}
          {invoiceData.currency.toUpperCase()}
        </Text>
        <Text>
          Customer:{" "}
          <Button
            onClick={() => {
              navigate(
                "/users/user/" + invoiceData.customer?.metadata?.firebaseUID
              );
            }}
          >
            {invoiceData.customer.name}
          </Button>
        </Text>
        <Text>
          Items:{" "}
          {invoiceData.lines.data.map((item: any) => {
            return (
              <Text key={item.id}>
                {item.description} ({item.type})
              </Text>
            );
          })}
        </Text>
      </VStack>
    </Container>
  );
};

export default InvoiceDetails;
