import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Button,
  Container,
  Flex,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const BunnyCosts = () => {
  const navigate = useNavigate();
  const [microsoftCosts, setMicrosoftCosts] = useState<any>(null);

  if (!microsoftCosts) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/finances/operation/costs/microsoft"
        authScope="read:business"
        pageData={microsoftCosts}
        setData={setMicrosoftCosts}
      />
    );
  }

  return (
    <Container maxW="container.lg">
      <Flex>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/business/costs")}
        >
          Costs list
        </Button>
        <Spacer />
        <Heading as="h1">Microsoft Billing Details</Heading>
        <Spacer />
      </Flex>
      <Heading mt={5} as="h2" size="md">
        Microsoft 365 subscriptions
      </Heading>
      <TableContainer mt={10}>
        <Table>
          <Thead>
            <Tr>
              <Td>Month</Td>
              <Td>Cost (USD)</Td>
              <Td>Comments</Td>
            </Tr>
          </Thead>
          <Tbody>
            {microsoftCosts.sort().map((cost: any, i: number) => (
              <Tr key={i}>
                <Td>{cost.date}</Td>
                <Td>{cost.billedAmount}</Td>
                <Td>{cost.comments}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default BunnyCosts;
