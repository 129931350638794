import { Button, Container, Heading, SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Subscriptions = () => {
  const navigate = useNavigate();
  return (
    <Container centerContent>
      <Heading>Subscriptions</Heading>
      <SimpleGrid mt={5} columns={2} spacing={3}>
        <Button
          onClick={() => {
            navigate("list");
          }}
        >
          List
        </Button>
        <Button
          isDisabled
          onClick={() => {
            navigate("stats");
          }}
        >
          Stats
        </Button>
      </SimpleGrid>
    </Container>
  );
};

export default Subscriptions;
