import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthPageGetData from "components/common/AuthPageGetData";
import { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

const Prices = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [searchParams] = useSearchParams();
  const productName = searchParams.get("pName");
  const [prices, setPrices] = useState<any>(null);
  const tableSize = useBreakpointValue({ base: "sm", xl: "md" });

  if (!prices) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/finances/stripe/products/" + productId}
        authScope="read:subscriptions"
        pageData={prices}
        setData={setPrices}
      />
    );
  }

  if (prices.length === 0) {
    return (
      <Box px={10}>
        <Flex>
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={() => navigate("/subscriptions/products")}
          >
            Product list
          </Button>
          <Spacer />
          <Heading>No prices found</Heading>
          <Spacer />
        </Flex>
      </Box>
    );
  }

  const transformedPrices = prices
    .sort((a: { active: boolean }) => {
      return a.active ? -1 : 1;
    })
    .map((p: any) => ({
      id: p.id,
      nickname: p.nickname ? p.nickname : "—",
      price: p.unit_amount / 100 + " " + p.currency.toUpperCase(),
      type: p.type.split("_").join(" "),
      interval: p.recurring
        ? p.recurring?.interval_count + " " + p.recurring?.interval
        : "N/A",
      active: p.active,
      created: new Date(p.created * 1000).toLocaleDateString(),
    }));

  return (
    <Box px={10}>
      <Flex>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/subscriptions/products")}
        >
          Product list
        </Button>
        <Spacer />
        <Heading>Prices{productName && ' for "' + productName + '"'}</Heading>
        <Spacer />
      </Flex>
      <TableContainer mt={10}>
        <Table size={tableSize}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Price</Th>
              <Th>Type</Th>
              <Th>Interval</Th>
              <Th>Status</Th>
              <Th>Created</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transformedPrices.map((p: any, i: number) => (
              <Tr key={i} backgroundColor={p.active ? "inherit" : "gray"}>
                <Td>{p.nickname}</Td>
                <Td>{p.price}</Td>
                <Td>{p.type}</Td>
                <Td>{p.interval}</Td>
                <Td>{p.active ? "active" : "archived"}</Td>
                <Td>{p.created}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Prices;
