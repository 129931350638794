import { Box, Heading } from "@chakra-ui/react";
import { type FC, useContext } from "react";
import AuthenticationButton from "components/app/AuthButton";
import { AuthContext } from "lib/context";

const Home: FC = () => {
  const { user } = useContext(AuthContext);
  return (
    <div>
      <Heading>
        {user ? "DroneCakes Admin" : "Please sign in to continue!"}
      </Heading>
      {!user && (
        <Box mt={5}>
          <AuthenticationButton menuItem={false} />
        </Box>
      )}
    </div>
  );
};

export default Home;
