import { useAuth0 } from "@auth0/auth0-react";
import { type AuthApiHookOptions, useAuthAPI } from "lib/hooks/useAuthAPI";
import { appConfig } from "config/constants";
import PageLoading from "components/app/PageLoading";
import { Button, Heading } from "@chakra-ui/react";
import { useEffect } from "react";

const AuthPageGetData = ({
  dataApiEndpoint,
  authScope,
  pageData,
  setData,
}: {
  dataApiEndpoint: string;
  authScope: string;
  pageData: any;
  setData: (data: any) => void;
}) => {
  const { isAuthenticated, getAccessTokenWithPopup } = useAuth0();
  const url = appConfig.API_BASE_URL + dataApiEndpoint;
  const options: AuthApiHookOptions = {
    authorizationParams: {
      audience: "DC-AD-API",
      scope: authScope,
    },
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const { loading, error, refresh, data, status } = useAuthAPI(url, options);
  const getTokenAndTryAgain = async () => {
    await getAccessTokenWithPopup(options);
    refresh();
  };

  useEffect(() => {
    if (!pageData) {
      setData(data);
    }
  }, [data, pageData, setData]);

  if (!isAuthenticated) {
    return null;
  }
  if (loading) {
    return <PageLoading />;
  }
  if (error) {
    if (error.error === "consent_required") {
      return <Button onClick={getTokenAndTryAgain}>Click to consent</Button>;
    }
    return <Heading>Error! {error.message}</Heading>;
  }
  if (status && status !== 200 && data) {
    return <Heading>Error! {data.message}</Heading>;
  }
  return <></>;
};

export default AuthPageGetData;
