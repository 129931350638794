import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { type FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthPageGetData from "components/common/AuthPageGetData";

const Chat: FC = () => {
  const navigate = useNavigate();
  const [chatsData, setChatsData] = useState<any>(null);

  if (!chatsData) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/chat/all"
        authScope="read:chat"
        pageData={chatsData}
        setData={setChatsData}
      />
    );
  }

  if (chatsData.length === 0) return <Text>No chats found</Text>;

  return (
    <Box px={20}>
      <Heading marginBottom={10}>Chat</Heading>
      <Flex paddingX={10} maxWidth={{ sm: "100px", md: "300px", lg: "8000px" }}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {chatsData.map((chatData: any, i: number) => (
              <Tr key={i}>
                <Td>
                  <Box>
                    {chatData.displayName ? (
                      chatData.displayName + " (" + chatData.username + ")"
                    ) : chatData.username ? (
                      chatData.username
                    ) : (
                      <Text fontStyle="italic" fontWeight="bold">
                        anonymous
                      </Text>
                    )}
                  </Box>
                </Td>
                <Td>{chatData.email ? chatData.email : chatData.uid}</Td>
                <Td>
                  <Button
                    onClick={() => {
                      navigate(chatData.uid + "/messages");
                    }}
                  >
                    View chat
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
    </Box>
  );
};

export default Chat;
