import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Button,
  Container,
  Flex,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { BunnyBillingRecord } from "customTypes/financeTypes";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const BunnyCosts = () => {
  const navigate = useNavigate();
  const [bunnyCosts, setBunnyCosts] = useState<any>(null);

  if (!bunnyCosts) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/finances/operation/costs/bunny"
        authScope="read:business"
        pageData={bunnyCosts}
        setData={setBunnyCosts}
      />
    );
  }

  const billingRecordTypes = {
    0: "payPal",
    1: "Bitcoin",
    2: "Credit card",
    3: "Monthly usage",
    4: "Refund",
    5: "Coupon code",
    6: "bank transfer",
    7: "Affiliate credits",
  };
  const findBillingRecordType = (type: keyof typeof billingRecordTypes) => {
    return billingRecordTypes[type];
  };

  return (
    <Container maxW="container.xl">
      <Flex>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/business/costs")}
        >
          Costs list
        </Button>
        <Spacer />
        <Heading as="h1">Bunny CDN Billing Details</Heading>
        <Spacer />
      </Flex>
      <Heading mt={5} as="h2" size="md">
        Currently used for e-learning
      </Heading>
      <VStack mt={10}>
        <Text>
          Current account balance: {bunnyCosts.Balance.toFixed(2)} USD
        </Text>
        <Text>VAT rate: {bunnyCosts.VATRate}%</Text>
      </VStack>
      <TableContainer mt={10}>
        <Table>
          <Thead>
            <Tr>
              <Td>Amount (net)</Td>
              <Td>Amount (gross)</Td>
              <Td>Date</Td>
              <Td>Type</Td>
              <Td>Invoice</Td>
            </Tr>
          </Thead>
          <Tbody>
            {bunnyCosts.BillingRecords.map((item: BunnyBillingRecord) => (
              <Tr key={item.Id}>
                <Td>{item.Amount.toFixed(item.Type === 3 ? 5 : 2)} USD</Td>
                <Td>
                  {(item.Amount * (1 + bunnyCosts.VATRate / 100)).toFixed(
                    item.Type === 3 ? 5 : 2
                  )}{" "}
                  USD
                </Td>
                <Td>{new Date(item.Timestamp).toLocaleDateString()}</Td>
                <Td>{findBillingRecordType(item.Type)}</Td>
                <Td>
                  {item.InvoiceAvailable ? (
                    <a
                      href={item.DocumentDownloadUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                    </a>
                  ) : (
                    <>Not available</>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default BunnyCosts;
