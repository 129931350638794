import { type Route } from "customTypes/appTypes";
import ProtectedRoute from "router/ProtectedRoute";
import Prices from "pages/Finances/Subscriptions/Prices";
import Products from "pages/Finances/Subscriptions/Products";
import PromoCodes from "pages/Finances/Subscriptions/PromoCodes";
import Coupons from "pages/Finances/Subscriptions/Coupons";
import PaymentDetails from "pages/Finances/Subscriptions/PaymentDetails";
import Payments from "pages/Finances/Subscriptions/Payments";
import PayoutDetails from "pages/Finances/Business/PayoutDetails";
import Payouts from "pages/Finances/Business/Payouts";
import MicrosoftCosts from "pages/Finances/Business/MicrosoftCosts";
import BunnyCosts from "pages/Finances/Business/BunnyCosts";
import OperationCosts from "pages/Finances/Business/OperationCosts";
import Finances from "pages/Finances/Finances";
import SubscriptionStats from "pages/Finances/Subscriptions/SubscriptionStats";
import SubscriptionsList from "pages/Finances/Subscriptions/SubscriptionsList";
import Subscriptions from "pages/Finances/Subscriptions/Subscriptions";
import InvoiceDetails from "pages/Finances/Subscriptions/InvoiceDetails";
import Invoices from "pages/Finances/Subscriptions/Invoices";

const financeRoutes: Route[] = [
  {
    name: "Payouts",
    route: "/stripe/payouts/:payoutId",
    element: <ProtectedRoute component={PayoutDetails} />,
    permission: "read:business",
  },
  {
    name: "Payouts",
    route: "/stripe/payouts",
    element: <ProtectedRoute component={Payouts} />,
    permission: "read:business",
  },
  {
    name: "Microsoft costs",
    route: "/business/costs/microsoft",
    element: <ProtectedRoute component={MicrosoftCosts} />,
    permission: "read:business",
  },
  {
    name: "Bunny CDN costs",
    route: "/business/costs/bunny",
    element: <ProtectedRoute component={BunnyCosts} />,
    permission: "read:business",
  },
  {
    name: "Operation costs",
    route: "/business/costs",
    element: <ProtectedRoute component={OperationCosts} />,
    permission: "read:business",
  },
  {
    name: "Invoice details",
    route: "/subscriptions/invoices/:invoiceId",
    element: <ProtectedRoute component={InvoiceDetails} />,
    permission: "read:business",
  },
  {
    name: "Invoices",
    route: "/subscriptions/invoices",
    element: <ProtectedRoute component={Invoices} />,
    permission: "read:business",
  },
  {
    name: "Prices",
    route: "/subscriptions/products/:productId",
    element: <ProtectedRoute component={Prices} />,
    permission: "read:subscriptions",
  },
  {
    name: "Products",
    route: "/subscriptions/products",
    element: <ProtectedRoute component={Products} />,
    permission: "read:subscriptions",
  },
  {
    name: "Promotion codes",
    route: "/subscriptions/coupons/:couponId",
    element: <ProtectedRoute component={PromoCodes} />,
    permission: "read:subscriptions",
  },
  {
    name: "Coupons",
    route: "/subscriptions/coupons",
    element: <ProtectedRoute component={Coupons} />,
    permission: "read:subscriptions",
  },
  {
    name: "Payment details",
    route: "/subscriptions/payments/:paymentId",
    element: <ProtectedRoute component={PaymentDetails} />,
    permission: "read:subscriptions",
  },
  {
    name: "Payments",
    route: "/subscriptions/payments",
    element: <ProtectedRoute component={Payments} />,
    permission: "read:subscriptions",
  },
  {
    name: "Subscription",
    route: "/subscriptions/stats",
    element: <ProtectedRoute component={SubscriptionStats} />,
    permission: "read:subscriptions",
  },
  {
    name: "Subscription",
    route: "/subscriptions/list",
    element: <ProtectedRoute component={SubscriptionsList} />,
    permission: "read:subscriptions",
  },
  {
    name: "Subscription",
    route: "/subscriptions",
    element: <ProtectedRoute component={Subscriptions} />,
    permission: "read:subscriptions",
  },
  {
    name: "Finances",
    route: "/finances",
    element: <ProtectedRoute component={Finances} />,
    navbar: true,
    permission: "read:subscriptions",
  },
];

export default financeRoutes;
