import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Box,
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Coupons = () => {
  const navigate = useNavigate();
  const [couponData, setCouponData] = useState<any>(null);
  const tableSize = useBreakpointValue({ base: "sm", xl: "md" });

  if (!couponData) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/finances/stripe/coupons"
        authScope="read:subscriptions"
        pageData={couponData}
        setData={setCouponData}
      />
    );
  }
  const transformedCouponData = couponData
    .filter((c: any) => c.valid)
    .map((c: any) => ({
      id: c.id,
      name: c.name,
      timesUsed: c.times_redeemed,
      maxRedemptions: c.max_redemptions,
      role: c.metadata?.role,
      duration:
        c.duration === "repeating"
          ? c.duration + " (" + c.duration_in_months + " months)"
          : c.duration,
      amountOff: c.amount_off
        ? c.amount_off / 100 + " " + c.currency.toUpperCase()
        : c.percent_off + "%",
      created: new Date(c.created * 1000).toLocaleDateString(),
    }));
  return (
    <Box px={10}>
      <Heading>Coupons</Heading>
      <Text>Click on the names for the user facing codes</Text>
      <TableContainer mt={10}>
        <Table size={tableSize}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Times used</Th>
              <Th>Max red.</Th>
              <Th>Role</Th>
              <Th>Duration</Th>
              <Th>Amount/percent off</Th>
              <Th>Created</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transformedCouponData.map((c: any, i: number) => (
              <Tr key={i}>
                <Td>
                  <Button
                    onClick={() => {
                      navigate(c.id, { state: { cName: c.name } });
                    }}
                  >
                    {c.name}
                  </Button>
                </Td>
                <Td>{c.timesUsed}</Td>
                <Td>{c.maxRedemptions}</Td>
                <Td>{c.role}</Td>
                <Td>{c.duration}</Td>
                <Td>{c.amountOff}</Td>
                <Td>{c.created}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Coupons;
