import { ArcElement, Chart, ChartData, Legend, Tooltip } from "chart.js";
import { Box, Heading } from "@chakra-ui/react";
import { Pie } from "react-chartjs-2";

export const PieChart = ({
  heading,
  data,
  options = {},
}: {
  heading: string;
  data: ChartData<"pie", any, string>;
  options?: any;
}) => {
  Chart.register(ArcElement, Tooltip, Legend);
  options = { ...options, responsive: true, maintainAspectRatio: true };
  return (
    <Box>
      <Heading as="h2" size="lg" mb={2}>
        {heading}
      </Heading>
      <Pie data={data} options={options} />
    </Box>
  );
};
