import { Button, Container, Heading, SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const navigate = useNavigate();
  return (
    <Container maxW="container.xl" centerContent>
      <Heading>DroneCakes users</Heading>
      <SimpleGrid mt={5} columns={2} spacing={3}>
        <Button
          onClick={() => {
            navigate("list");
          }}
        >
          Latest users
        </Button>
        <Button
          onClick={() => {
            navigate("stats");
          }}
        >
          User statistics
        </Button>
      </SimpleGrid>
    </Container>
  );
};

export default Users;
