import {
  Heading,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react";

export const VercelTable = ({
  vercelCosts,
}: {
  vercelCosts: { date: string; totalToPay: number }[];
}) => {
  return (
    <TableContainer>
      <Heading mb={5} as="h2" size="md">
        Vercel Costs
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Td>Date</Td>
            <Td>Cost (USD)</Td>
          </Tr>
        </Thead>
        <Tbody>
          {vercelCosts
            .sort((a, b) => {
              return b.date.localeCompare(a.date);
            })
            .map((cost, i: number) => (
              <Tr key={i}>
                <Td>{new Date(cost.date).toLocaleDateString()}</Td>
                <Td>{cost.totalToPay}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export const GcpTable = ({
  gcpCosts,
}: {
  gcpCosts: { date: string; totalExact: number }[];
}) => {
  return (
    <TableContainer>
      <Heading mb={5} as="h2" size="md">
        Google Cloud Platform (GCP) Costs
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Td>Month</Td>
            <Td>Cost (USD)</Td>
          </Tr>
        </Thead>
        <Tbody>
          {gcpCosts
            .sort((a, b) => {
              return b.date.localeCompare(a.date);
            })
            .map((cost, i: number) => (
              <Tr key={i}>
                <Td>{cost.date}</Td>
                <Td>{cost.totalExact}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export const OtherTable = ({
  otherCosts,
}: {
  otherCosts: {
    comments: string[];
    cost: string;
    provider: string;
    service: string;
  }[];
}) => {
  return (
    <TableContainer>
      <Heading mb={5} as="h2" size="md">
        Other Costs
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Td>Provider</Td>
            <Td>Service</Td>
            <Td>Cost (USD)</Td>
            <Td>Comments</Td>
          </Tr>
        </Thead>
        <Tbody>
          {otherCosts
            .sort((a, b) => {
              return b.provider.localeCompare(a.provider);
            })
            .map((cost, i: number) => (
              <Tr key={i}>
                <Td>{cost.provider}</Td>
                <Td>{cost.service}</Td>
                <Td>{cost.cost}</Td>
                <Td>
                  {cost.comments.map((comment, j: number) => (
                    <UnorderedList key={j}>
                      <ListItem>{comment}</ListItem>
                    </UnorderedList>
                  ))}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export const BillingoTable = ({
  billingoCosts,
}: {
  billingoCosts: {
    fulfillment_date: string;
    total_gross: number;
    currency: string;
  }[];
}) => {
  return (
    <TableContainer>
      <Heading mb={5} as="h2" size="md">
        Billingo Costs
      </Heading>
      <Table>
        <Thead>
          <Tr>
            <Td>Date</Td>
            <Td>Cost</Td>
          </Tr>
        </Thead>
        <Tbody>
          {billingoCosts
            .sort((a, b) => {
              return b.fulfillment_date.localeCompare(a.fulfillment_date);
            })
            .map((cost, i: number) => (
              <Tr key={i}>
                <Td>{new Date(cost.fulfillment_date).toLocaleDateString()}</Td>
                <Td>
                  {cost.total_gross} {cost.currency}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
