import { Dispatch } from "react";
import { appConfig } from "config/constants";

export const createVideoBunny = async (
  bunnyCollection: string,
  bunnyVideoTitle: string,
  writeAccessToken: string,
  setBunnyNewVideoData: Dispatch<any>,
  setVideoCreated: Dispatch<any>
) => {
  if (!bunnyCollection || !bunnyVideoTitle) {
    return alert("Please select a collection and a video to upload");
  }
  try {
    const response = await fetch(appConfig.API_BASE_URL + "/videos/cdn/video", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${writeAccessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: bunnyVideoTitle,
        collectionId: bunnyCollection,
      }),
    });
    const {
      videoLibraryId,
      collectionId,
      guid,
      expirationTime,
      authSignature,
    } = await response.json();
    if (guid) {
      setBunnyNewVideoData({
        videoLibraryId,
        collectionId,
        guid,
        expirationTime,
        authSignature,
      });
      setVideoCreated(true);
    }
  } catch (e) {
    console.error(e);
  }
};

export const uploadVideoDataToDB = async (
  writeAccessToken: string,
  courseID: string,
  libraryId: number,
  videoId: string,
  videoTitle: string,
  isTrailer = false,
  videoNum?: number
) => {
  const apiEndpoint = isTrailer
    ? "/videos/db/trailer/create"
    : "/videos/db/video/create";
  const embedUrl =
    "https://iframe.mediadelivery.net/embed/" +
    libraryId.toString() +
    "/" +
    videoId +
    "?autoplay=false&preload=false";
  let queryBody: {
    courseID: string;
    embedUrl: string;
    locked: boolean;
    title: string;
    videoNum?: number;
  } = {
    courseID,
    embedUrl,
    locked: true,
    title: videoTitle,
  };
  if (isTrailer) {
    queryBody = {
      ...queryBody,
      videoNum,
    };
  }
  return await fetch(appConfig.API_BASE_URL + apiEndpoint, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${writeAccessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(queryBody),
  });
};
