import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Heading,
  HStack,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const DiscountDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate("/e-learning/manage");
    }
  }, [navigate, state]);

  return (
    <Container centerContent>
      <Heading as="h1">Discount codes for:</Heading>
      <HStack>
        <Heading as="h2" size="md">
          {state.short_name}
        </Heading>
        <IconButton
          onClick={() => window.open(state.access_url, "_blank", "noreferrer")}
          ml={2}
          aria-label="Access url"
          icon={<ExternalLinkIcon />}
        />
      </HStack>
      <Button
        mt={5}
        onClick={() => {
          window.open(
            `https://dronecakes.com/discounts/${state.id}`,
            "_blank",
            "noreferrer"
          );
        }}
      >
        dronecakes.com
      </Button>
      <VStack mt={10} spacing={3}>
        <Text>{state.comp_name}</Text>
        <Text>{state.subtitle1}</Text>
        <Text>{state.discount}% discount</Text>
        <Text>Remaining codes: {state.remaining_amount}</Text>
      </VStack>
    </Container>
  );
};

export default DiscountDetails;
