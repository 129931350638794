import { ColorModeScript } from "@chakra-ui/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { unregisterServiceWorker } from "./serviceWorker";
import Auth0ProviderWithConfig from "lib/auth/Auth0ProviderWithConfig";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container);

root.render(
  <StrictMode>
    <Auth0ProviderWithConfig>
      <ColorModeScript initialColorMode="system" />
      <App />
    </Auth0ProviderWithConfig>
  </StrictMode>
);

unregisterServiceWorker();

reportWebVitals();
