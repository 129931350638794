import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithConfig = ({ children }: any) => {
  const domain = "dronecakes.eu.auth0.com";
  const clientId = "5peLatF9h8MahCjO3rIUUYwwsUcr1uIM";
  const redirectUri = window.location.origin + "/auth/callback";

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{ redirect_uri: redirectUri }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithConfig;
