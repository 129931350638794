import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import AuthenticationButton from "./AuthButton";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
import { type FC, useContext } from "react";
import { AuthContext } from "lib/context";

const ProfileMenu = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  return (
    <Box>
      <Menu>
        <MenuButton>
          <Avatar size="sm" cursor="pointer" src={user ? user.picture : ""} />
        </MenuButton>
        <MenuList minWidth="150px">
          {user && (
            <MenuItem
              onClick={() => {
                navigate("auth/profile");
              }}
            >
              Profile
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              window.open(
                "https://app.appsmith.com/app/dronecakes-admin/homepage-62e413c74003a7259a2f881d?branch=main",
                "_blank",
                "noreferrer"
              );
            }}
          >
            Old admin site
          </MenuItem>
          <AuthenticationButton />
        </MenuList>
      </Menu>
    </Box>
  );
};

const DesktopNav = ({ navItems }: { navItems: any[] }) => {
  const navigate = useNavigate();
  const { user, permissions } = useContext(AuthContext);
  return (
    <Flex justify="space-between" flex="1">
      <ButtonGroup variant="link" spacing="4">
        {user &&
          navItems
            .filter((i) => !i.permission || permissions.includes(i.permission))
            .map((item) => (
              <Button
                onClick={() => {
                  navigate(item.route);
                }}
                key={item.route}
              >
                {item.name}
              </Button>
            ))}
      </ButtonGroup>
      <HStack spacing="3">
        <ColorModeSwitcher justifySelf="flex-end" />
        <ProfileMenu />
      </HStack>
    </Flex>
  );
};

const MobileNav: FC = ({
  navigate = null,
  navItems = [],
}: {
  navigate?: any;
  navItems?: any[];
}) => {
  return (
    <Text color="red" fontSize="lg">
      please note that this admin panel is not yet optimized for mobile, use a
      desktop to view this page
    </Text>
    /*<IconButton
      variant="ghost"
      icon={<FiMenu fontSize="1.25rem" />}
      aria-label="Open Menu"
    />*/
  );
};

const NavBar = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const navItems = routes.filter((r) => r.navbar);
  return (
    <Box
      mb={5}
      as="nav"
      bg="bg-surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
    >
      <Container py={{ base: "4", lg: "5" }}>
        <HStack spacing="10" justify="space-between">
          {isDesktop ? <DesktopNav navItems={navItems} /> : <MobileNav />}
        </HStack>
      </Container>
    </Box>
  );
};

export default NavBar;
