import {
  Button,
  Center,
  Container,
  Heading,
  IconButton,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useContext, useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import { AuthContext } from "lib/context";

const UserDetails = () => {
  const { roles } = useContext(AuthContext);
  const { userId } = useParams();

  const [userData, setUserData] = useState<any>(null);

  if (!userData) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/users/user/" + userId}
        authScope="read:users"
        pageData={userData}
        setData={setUserData}
      />
    );
  }

  if (!userData.dbData) {
    return <Text color="red">User not found</Text>;
  }

  return (
    <Container>
      {userData.dbData.profImage && (
        <Center>
          <Image
            borderRadius="full"
            boxSize="100px"
            src={userData.dbData.profImage}
            alt={userData.dbData.username + "'s profile image"}
          />
        </Center>
      )}
      <Heading as="h1" py={5}>
        {userData.dbData.displayName
          ? userData.dbData.displayName + " (" + userData.dbData.username + ")"
          : userData.dbData.username}
        <IconButton
          onClick={() =>
            window.open(
              process.env.REACT_APP_DRONECAKES_WEBSITE_DOMAIN +
                "droners/" +
                userData.dbData.username,
              "_blank",
              "noreferrer"
            )
          }
          ml={2}
          aria-label={userData.dbData.username + "'s profile page"}
          icon={<ExternalLinkIcon />}
        />
      </Heading>
      <VStack>
        <Text>Subscription: {userData.dbData.subscriptionLevel}</Text>
        {userData.dbData.countryObject?.long_name && (
          <Text>Country: {userData.dbData.countryObject?.long_name}</Text>
        )}
        {userData.authData?.providerData &&
          userData.authData?.providerData.length !== 0 && (
            <Text>
              Login methods:{" "}
              {userData.authData.providerData
                .map((p: any) => p.providerId)
                .join(", ")}
            </Text>
          )}
        {roles.includes("Stripe") && userData.dbData.stripeLink && (
          <Button
            onClick={() =>
              window.open(userData.dbData.stripeLink, "_blank", "noreferrer")
            }
          >
            Stripe page
          </Button>
        )}
      </VStack>
    </Container>
  );
};

export default UserDetails;
