import { useEffect, useState } from "react";
import { type GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";

export type AuthApiHookOptions = GetTokenSilentlyOptions & RequestInit;

export const useAuthAPI = (url: string, options: AuthApiHookOptions = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState<{
    error: any;
    loading: boolean;
    data: any;
    status?: number;
  }>({
    error: null,
    loading: true,
    data: null,
  });
  const [refreshIndex, setRefreshIndex] = useState(0);
  const [tries, setTries] = useState(0);

  useEffect(() => {
    if (tries < 1) {
      (async () => {
        try {
          const { authorizationParams, ...fetchOptions } = options;
          const accessToken = await getAccessTokenSilently({
            authorizationParams,
          });
          const res = await fetch(url, {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${accessToken}`,
            },
          });
          if (!res.ok) {
            setTries(tries + 1);
            return setState({
              ...state,
              error: new Error(res.statusText),
              loading: false,
            });
          }
          setTries(tries + 1);
          return setState({
            ...state,
            status: res.status,
            data: await res.json(),
            error: null,
            loading: false,
          });
        } catch (error) {
          setTries(tries + 1);
          return setState({
            ...state,
            error,
            loading: false,
          });
        }
      })();
    }
  }, [getAccessTokenSilently, options, refreshIndex, state, tries, url]);

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};
