import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Box, Text } from "@chakra-ui/react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import palette from "google-palette";
import { countBy } from "lodash";
import { DateTime, Interval } from "luxon";
import { PieChart } from "components/common/Charts";

export const SubscriptionsChart = ({ users }: { users: any }) => {
  const labels = ["free", "standard", "enterprise", "regNotFinished"];
  const usersBySubLevel = labels.map((label) =>
    label === "regNotFinished"
      ? users?.filter((d: any) => !("subscriptionLevel" in d)).length
      : users?.filter(
          (d: { subscriptionLevel: string }) => d.subscriptionLevel === label
        ).length
  );
  const chartData = {
    labels,
    datasets: [
      {
        label: "# of users",
        data: usersBySubLevel,
        backgroundColor: palette("cb-RdYlBu", usersBySubLevel.length).map(
          (hex: string) => `#${hex}`
        ),
        borderWidth: 1,
      },
    ],
  };
  return <PieChart heading="Subscriptions" data={chartData} />;
};

export const SkillsChart = ({ users }: { users: any }) => {
  const labels = [];
  const data = [];
  const skillsData = users
    ?.map((d: { skills: string | string[] }) => {
      if (d.skills && d.skills.length !== 0) {
        return d.skills;
      }
    })
    .flat()
    .filter((e: any) => !!e)
    .reduce((acc: { [x: string]: number }, curr: string | number) => {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});

  for (const [key, value] of Object.entries(skillsData)) {
    labels.push(key);
    data.push(value);
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: "# of users using this skill",
        data,
        backgroundColor: palette("tol-dv", data.length).map(
          (hex: string) => `#${hex}`
        ),
        borderWidth: 1,
      },
    ],
  };
  return <PieChart heading="Skills" data={chartData} />;
};

export const CountriesChart = ({ users }: { users: any }) => {
  const labels = [];
  const data = [];
  const countriesData = users
    ?.map((d: { countryObject: { long_name: string | any[] } }) => {
      if (
        d.countryObject?.long_name &&
        d.countryObject.long_name.length !== 0
      ) {
        return d.countryObject.long_name;
      }
    })
    .flat()
    .filter((e: any) => !!e)
    .reduce((acc: { [x: string]: number }, curr: string | number) => {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});
  for (const [key, value] of Object.entries(countriesData)) {
    labels.push(key);
    data.push(value);
  }
  const options = { plugins: { legend: { display: false } } };
  const chartData = {
    labels,
    datasets: [
      {
        label: "# of users from this country",
        data,
        backgroundColor: palette("tol-dv", data.length).map(
          (hex: string) => `#${hex}`
        ),
        borderWidth: 1,
      },
    ],
  };
  return <PieChart heading="Countries" data={chartData} options={options} />;
};

export const NewUsersChart = ({ users }: { users: any }) => {
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  let regFinData: any[] = [];
  let regUnFinData: any[] = [];
  const regFinDates = [];
  const regUnFinDates = [];

  const showDataFromThisManyDaysAgo = 90;

  users = users.filter((u: any) => u.userCreated);
  let regFinishedUsers = users.filter((u: any) => u.username);
  let regUnfinishedUsers = users.filter((u: any) => !u.username);

  regFinishedUsers = regFinishedUsers
    .map((user: any) => {
      const seconds = user.userCreated._seconds || user.userCreated.seconds;
      const date = DateTime.fromSeconds(seconds).toISODate();
      return { ...user, userCreationDateForChart: date };
    })
    .filter(
      (u: any) =>
        DateTime.fromISO(u.userCreationDateForChart) >
        DateTime.local().minus({ days: showDataFromThisManyDaysAgo })
    );
  regUnfinishedUsers = regUnfinishedUsers
    .map((user: any) => {
      const seconds = user.userCreated._seconds || user.userCreated.seconds;
      const date = DateTime.fromSeconds(seconds).toISODate();
      return { ...user, userCreationDateForChart: date };
    })
    .filter(
      (u: any) =>
        DateTime.fromISO(u.userCreationDateForChart) >
        DateTime.local().minus({ days: showDataFromThisManyDaysAgo })
    );

  if (regFinishedUsers.length === 0 && regUnfinishedUsers.length === 0) {
    return (
      <Text>No new users in the last {showDataFromThisManyDaysAgo} days</Text>
    );
  }

  regFinishedUsers = countBy(regFinishedUsers, "userCreationDateForChart");
  regUnfinishedUsers = countBy(regUnfinishedUsers, "userCreationDateForChart");

  if (regFinishedUsers.length !== 0) {
    for (const [key, value] of Object.entries(regFinishedUsers)) {
      regFinData.push({ x: DateTime.fromISO(key), y: value });
      regFinDates.push(DateTime.fromISO(key));
    }
  }
  if (regUnfinishedUsers.length !== 0) {
    for (const [key, value] of Object.entries(regUnfinishedUsers)) {
      regUnFinData.push({ x: DateTime.fromISO(key), y: value });
      regUnFinDates.push(DateTime.fromISO(key));
    }
  }

  const compareDates = (a: { x: DateTime }, b: { x: DateTime }) => {
    return a.x.toMillis() - b.x.toMillis();
  };

  regFinData.sort(compareDates);
  regUnFinData.sort(compareDates);

  if (regFinData.length !== 0) {
    const regFinOldestDate = DateTime.min(...regFinDates);
    const regFinNewestDate = DateTime.now();
    const regFinInterval = Interval.fromDateTimes(
      regFinOldestDate,
      regFinNewestDate
    )
      .splitBy({ days: 1 })
      .map((d) => d.start.toISODate());

    regFinData = regFinData.map((d) => ({ x: d.x.toISODate(), y: d.y }));

    regFinData = regFinInterval.map((d: string) => {
      const dataPoint = regFinData.find((e: { x: string }) => e.x === d);
      if (dataPoint) {
        return dataPoint;
      } else {
        return { x: d, y: 0 };
      }
    });
  }
  if (regUnFinData.length !== 0) {
    const regUnFinOldestDate = DateTime.min(...regUnFinDates);
    const regUnFinNewestDate = DateTime.now();
    const regUnFinInterval = Interval.fromDateTimes(
      regUnFinOldestDate,
      regUnFinNewestDate
    )
      .splitBy({ days: 1 })
      .map((d) => d.start.toISODate());

    regUnFinData = regUnFinData.map((d) => ({ x: d.x.toISODate(), y: d.y }));

    regUnFinData = regUnFinInterval.map((d: string) => {
      const dataPoint = regUnFinData.find((e: { x: string }) => e.x === d);
      if (dataPoint) {
        return dataPoint;
      } else {
        return { x: d, y: 0 };
      }
    });
  }

  const chartData = {
    datasets: [
      {
        fill: true,
        lineTension: 0.3,
        label: "registered new users",
        data: regFinData,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        fill: true,
        lineTension: 0.3,
        label: "not fully registered new users",
        data: regUnFinData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <Box px={3}>
      <Line
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            y: {
              suggestedMin: 0,
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </Box>
  );
};
