import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";

const Payments = () => {
  const navigate = useNavigate();
  const [payments, setPayments] = useState<any>(null);
  const tableSize = useBreakpointValue({ base: "sm", xl: "md" });

  if (!payments) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/finances/stripe/payments"
        authScope="read:subscriptions"
        pageData={payments}
        setData={setPayments}
      />
    );
  }

  return (
    <TableContainer>
      <Table size={tableSize}>
        <Thead>
          <Tr>
            <Td>Created</Td>
            <Td>Status</Td>
            <Td>Amount</Td>
            <Td>Amount received</Td>
            <Td>Description</Td>
            <Td>Actions</Td>
          </Tr>
        </Thead>
        <Tbody>
          {payments.map((p: any, i: number) => (
            <Tr key={i}>
              <Td>{new Date(p.created * 1000).toLocaleDateString()}</Td>
              <Td>{p.status.split("_").join(" ")}</Td>
              <Td>
                {p.amount / 100} {p.currency.toUpperCase()}
              </Td>
              <Td>
                {p.amount_received / 100} {p.currency.toUpperCase()}
              </Td>
              <Td>{p.description ? p.description : "N/A"}</Td>
              <Td>
                <Button
                  onClick={() => {
                    navigate(p.id);
                  }}
                >
                  Check details
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default Payments;
