import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

const UsersTable = ({ users }: { users: any[] }) => {
  const navigate = useNavigate();
  return (
    <Flex paddingX={10} maxWidth={{ sm: "100px", md: "300px", lg: "8000px" }}>
      <Table variant="striped" colorScheme="teal" size="sm">
        <Thead>
          <Tr>
            <Th>username</Th>
            <Th>display name</Th>
            <Th>sub. level</Th>
            <Th>email</Th>
            <Th>user created</Th>
            <Th>details</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users
            .filter((u) => !u.internalUser)
            .filter((u) => u.userCreated)
            .filter((u) => u.username)
            .map((u) => {
              const seconds = u.userCreated._seconds || u.userCreated.seconds;
              const userCreatedDate = DateTime.fromSeconds(seconds);
              return { ...u, userCreatedDate };
            })
            .sort((a, b) => b.userCreatedDate - a.userCreatedDate)
            .slice(0, 20)
            .map((user, i) => {
              return (
                <Tr key={i}>
                  <Td>{user.username}</Td>
                  <Td>{user.displayName}</Td>
                  <Td>
                    {user.subscriptionLevel === "standard"
                      ? "basic"
                      : user.subscriptionLevel}
                  </Td>
                  <Td>{user.email}</Td>
                  <Td>{user.userCreatedDate.toLocaleString()}</Td>
                  <Td>
                    <Button
                      size="sm"
                      colorScheme="green"
                      onClick={() => {
                        navigate("/users/user/" + user.uid);
                      }}
                    >
                      DETAILS
                    </Button>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </Flex>
  );
};

export default UsersTable;
