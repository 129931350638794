import { useContext, useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "lib/context";
import { type Subscription } from "customTypes/financeTypes";

const SubscriptionsList = () => {
  const navigate = useNavigate();
  const { roles } = useContext(AuthContext);
  const [subscriptionsData, setSubscriptionsData] = useState<
    Subscription[] | null
  >(null);

  if (!subscriptionsData) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/finances/stripe/subscriptions"}
        authScope="read:subscriptions"
        pageData={subscriptionsData}
        setData={setSubscriptionsData}
      />
    );
  }

  return (
    <TableContainer>
      <Heading as="h2" size="lg" mb={4}>
        Subscriptions
      </Heading>
      <Table size="sm">
        <Thead>
          <Tr>
            <Td>Created</Td>
            <Td>Plan</Td>
            <Td>Status</Td>
            <Td>Period start</Td>
            <Td>Period end</Td>
            <Td>Customer</Td>
            <Td>Amount due (invoice)</Td>
            <Td>Latest invoice</Td>
          </Tr>
        </Thead>
        <Tbody>
          {subscriptionsData?.map((subscription) => (
            <Tr key={subscription.id}>
              <Td>
                {subscription.created &&
                  new Date(subscription.created * 1000).toLocaleDateString()}
              </Td>
              <Td>
                {subscription.plan?.product?.name} /{" "}
                {subscription.plan?.interval}
              </Td>
              <Td>{subscription.status}</Td>
              <Td>
                {new Date(
                  subscription.current_period_start * 1000
                ).toLocaleDateString()}
              </Td>
              <Td>
                {new Date(
                  subscription.current_period_end * 1000
                ).toLocaleDateString()}
              </Td>
              <Td>
                <Button
                  size="sm"
                  onClick={() => {
                    navigate(
                      "/users/user/" +
                        subscription.customer?.metadata?.firebaseUID
                    );
                  }}
                >
                  {subscription.customer?.name}
                </Button>
              </Td>
              <Td>
                {subscription.latest_invoice?.amount_due / 100}{" "}
                {subscription.latest_invoice?.currency.toUpperCase()} (
                {subscription.latest_invoice?.status})
              </Td>
              <Td>
                <Button
                  size="sm"
                  onClick={() => {
                    navigate(
                      "/subscriptions/invoices/" +
                        subscription.latest_invoice?.id
                    );
                  }}
                >
                  Invoice
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SubscriptionsList;
