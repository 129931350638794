import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import { FormEvent, useEffect, useState } from "react";
import { appConfig } from "config/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

const CreateEditEvent = () => {
  const { pathname, state } = useLocation();
  const isEdit = pathname.includes("edit");
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [name, setName] = useState<string>(
    isEdit && state?.event.name ? state?.event.name : ""
  );
  const [date, setDate] = useState<string | any>(
    isEdit && state?.event.date
      ? DateTime.fromMillis(state?.event.date).toFormat("yyyy-MM-dd'T'HH:mm")
      : ""
  );
  const [eventUrl, setEventUrl] = useState<string>(
    isEdit && state?.event.eventUrl ? state?.event.eventUrl : ""
  );
  const [ticketUrl, setTicketUrl] = useState<string>(
    isEdit && state?.event.ticketUrl ? state?.event.ticketUrl : ""
  );
  const [writeAccessToken, setWriteAccessToken] = useState<string>("");

  useEffect(() => {
    if (isEdit && !state) {
      navigate("/events/list");
    }
  }, [isEdit, navigate, state]);

  useEffect(() => {
    const getWriteAccessToken = async () => {
      return await getAccessTokenSilently({
        authorizationParams: {
          audience: "DC-AD-API",
          scope: "write:events",
        },
      });
    };
    getWriteAccessToken()
      .then((accessToken: string) => {
        setWriteAccessToken(accessToken);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [getAccessTokenSilently]);

  const editEvent = async (e: FormEvent) => {
    e.preventDefault();
    const eventData = {
      name,
      date: DateTime.fromISO(date).toMillis(),
      eventUrl,
      ticketUrl,
    };
    try {
      const res = await fetch(appConfig.API_BASE_URL + "/events/edit", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${writeAccessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eventData: { ...eventData, uid: state?.event.uid },
        }),
      });
      if (res.ok) {
        navigate("/events/list");
      } else {
        return alert("Error editing event");
      }
    } catch (e) {
      return alert("Error editing event");
    }
  };

  const createEvent = async (e: FormEvent) => {
    e.preventDefault();
    const eventData = {
      name,
      date: DateTime.fromISO(date).toMillis(),
      eventUrl,
      ticketUrl,
    };
    try {
      await fetch(appConfig.API_BASE_URL + "/events/create", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${writeAccessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ eventData }),
      });
      navigate("/events/list");
    } catch (e) {
      return alert("Could not create event");
    }
  };

  return (
    <Container>
      <Heading my={5} as="h1">
        {isEdit ? "Edit event" : "Create event"}
      </Heading>
      <Container mt={10} maxW="md">
        <form onSubmit={isEdit ? editEvent : createEvent}>
          <VStack spacing={4}>
            <FormControl isRequired={true}>
              <FormLabel>Event name</FormLabel>
              <Input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Add event name"
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Event date</FormLabel>
              <Input
                type="datetime-local"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                placeholder="Add event date"
              />
            </FormControl>
            <FormControl isRequired={true}>
              <FormLabel>Event url</FormLabel>
              <Input
                type="url"
                value={eventUrl}
                onChange={(e) => {
                  setEventUrl(e.target.value);
                }}
                placeholder="Add event url"
              />
            </FormControl>
            <FormControl isRequired={false}>
              <FormLabel>Ticket url</FormLabel>
              <Input
                type="url"
                value={ticketUrl}
                onChange={(e) => {
                  setTicketUrl(e.target.value);
                }}
                placeholder="Add ticket url"
              />
            </FormControl>
          </VStack>
          <Button mt={10} type="submit">
            {isEdit ? "Edit event" : "Create event"}
          </Button>
        </form>
      </Container>
    </Container>
  );
};

export default CreateEditEvent;
