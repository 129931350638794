import { Box, Heading, VStack } from "@chakra-ui/react";
import { FC, useState } from "react";
import UsersTable from "components/Users/UsersTable";
import AuthPageGetData from "components/common/AuthPageGetData";
import { NewUsersChart } from "components/Users/UserStatCharts";

const UserStats: FC = () => {
  const [usersData, setUsersData] = useState<any>(null);

  if (!usersData) {
    return (
      <AuthPageGetData
        authScope="read:users"
        dataApiEndpoint="/users/all"
        pageData={usersData}
        setData={setUsersData}
      />
    );
  }
  return (
    <Box px={3}>
      <Heading as="h1" size="xl" mb={10}>
        Latest Users
      </Heading>
      <Box mb={20}>
        <NewUsersChart users={usersData} />
      </Box>
      <UsersTable users={usersData.filter((u: any) => u.username)} />
    </Box>
  );
};

export default UserStats;
