import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { FC, useState } from "react";
import {
  CountriesChart,
  SkillsChart,
  SubscriptionsChart,
} from "components/Users/UserStatCharts";
import AuthPageGetData from "components/common/AuthPageGetData";

const UserStats: FC = () => {
  const [usersData, setUsersData] = useState<any>(null);

  if (!usersData) {
    return (
      <AuthPageGetData
        authScope="read:users"
        dataApiEndpoint="/users/all"
        pageData={usersData}
        setData={setUsersData}
      />
    );
  }
  return (
    <Box px={3}>
      <Heading as="h1" size="xl" mb={10}>
        User stats
      </Heading>
      <SimpleGrid columns={{ lg: 3, md: 2 }} spacing={5}>
        <SubscriptionsChart users={usersData} />
        <SkillsChart users={usersData} />
        <CountriesChart users={usersData} />
      </SimpleGrid>
    </Box>
  );
};

export default UserStats;
