import { useContext, useEffect, useRef, useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Container,
  Heading,
  IconButton,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Event } from "customTypes/otherTypes";
import { DateTime } from "luxon";
import { AuthContext } from "lib/context";
import { appConfig } from "config/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const EventList = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { permissions } = useContext(AuthContext);
  const [writeAccessToken, setWriteAccessToken] = useState<string>("");
  const [events, setEvents] = useState<Event[] | null>(null);

  useEffect(() => {
    const getWriteAccessToken = async () => {
      return await getAccessTokenSilently({
        authorizationParams: {
          audience: "DC-AD-API",
          scope: "write:events",
        },
      });
    };
    getWriteAccessToken()
      .then((accessToken: string) => {
        setWriteAccessToken(accessToken);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [getAccessTokenSilently]);

  if (!events) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/events/all"
        authScope="read:events"
        pageData={events}
        setData={setEvents}
      />
    );
  }

  const deleteEvent = async (event: Event, onClose: () => void) => {
    try {
      const response = await fetch(appConfig.API_BASE_URL + "/events/delete", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${writeAccessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid: event.uid }),
      });
      if (response.ok) {
        setEvents(events.filter((e) => e.uid !== event.uid));
        onClose();
      } else {
        alert("Sorry, something went wrong");
      }
    } catch (err) {
      alert("Sorry, something went wrong");
    }
  };

  const TableRow = ({ event }: { event: Event }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef(null);
    return (
      <Tr>
        <Td maxWidth={{ lg: 450, md: 200 }}>
          <Text isTruncated>{event.name}</Text>
        </Td>
        <Td>
          <IconButton
            aria-label="user-requests"
            icon={<ExternalLinkIcon />}
            onClick={() => {
              navigate("/events/messages", { state: { event: event.uid } });
            }}
          />
        </Td>
        <Td>
          {DateTime.fromMillis(event.date)
            .setLocale("hu")
            .toLocaleString(DateTime.DATETIME_FULL)}
        </Td>
        {permissions.includes("write:events") && (
          <Td>
            <SimpleGrid columns={2} spacing={2}>
              <Button
                onClick={() => {
                  navigate("/events/edit", { state: { event } });
                }}
              >
                Edit
              </Button>
              <Button color="red" onClick={onOpen}>
                Delete
              </Button>
            </SimpleGrid>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Event ({event.name})
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure? You cannot undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        deleteEvent(event, onClose).catch();
                      }}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Td>
        )}
      </Tr>
    );
  };

  return (
    <Container maxW="container.lg">
      <Heading my={5} as="h1">
        Events
      </Heading>
      <Button
        onClick={() => {
          navigate("/events/create");
        }}
      >
        Create new event
      </Button>
      <TableContainer mt={10}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Td>Event name</Td>
              <Td>User requests</Td>
              <Td>Event date</Td>
              {permissions.includes("write:events") && <Td>Actions</Td>}
            </Tr>
          </Thead>
          <Tbody>
            {events
              .sort((a, b) => {
                return (
                  DateTime.fromMillis(a.date).toMillis() -
                  DateTime.fromMillis(b.date).toMillis()
                );
              })
              .map((event) => (
                <TableRow key={event.uid} event={event} />
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default EventList;
