import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";

const Payouts = () => {
  const navigate = useNavigate();
  const { payoutId } = useParams();
  const [payoutDetails, setPayoutDetails] = useState<any>(null);
  const tableSize = useBreakpointValue({ base: "sm", xl: "md" });

  if (!payoutDetails) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/finances/stripe/payouts/" + payoutId}
        authScope="read:business"
        pageData={payoutDetails}
        setData={setPayoutDetails}
      />
    );
  }

  return (
    <Box px={10}>
      <Flex>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/stripe/payouts")}
        >
          Payout list
        </Button>
        <Spacer />
        <Heading>Payout details</Heading>
        <Spacer />
      </Flex>
      <TableContainer my={10}>
        <Table size={tableSize}>
          <Thead>
            <Tr>
              <Td>Type</Td>
              <Td>Amount</Td>
              <Td>Fee</Td>
              <Td>Net</Td>
              <Td>Description</Td>
              <Td>Creation date</Td>
            </Tr>
          </Thead>
          <Tbody>
            {payoutDetails.map((p: any, i: number) => (
              <Tr key={i}>
                <Td>{p.type}</Td>
                <Td>
                  {p.amount / 100} {p.currency.toUpperCase()}
                </Td>
                <Td>
                  {p.fee / 100} {p.currency.toUpperCase()}
                </Td>
                <Td>
                  {p.net / 100} {p.currency.toUpperCase()}
                </Td>
                <Td>
                  {p.description}
                  {p.type === "charge" &&
                    ` (${p.source?.billing_details?.name ?? "UNKNOWN"})`}
                </Td>
                <Td>{new Date(p.created * 1000).toLocaleDateString()}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Payouts;
