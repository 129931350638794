import {
  Button,
  Container,
  Heading,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import { ElearningCoursesDocument } from "customTypes/elearningTypes";
import { useNavigate } from "react-router-dom";

const ManageCourses = () => {
  const navigate = useNavigate();
  const [coursesData, setCoursesData] = useState<
    ElearningCoursesDocument[] | null
  >(null);

  if (!coursesData) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/videos/courses/all"
        authScope="read:elearning"
        pageData={coursesData}
        setData={setCoursesData}
      />
    );
  }

  return (
    <Container maxW="container.lg">
      <Heading mb={10} as="h1">
        Manage e-learning courses
      </Heading>
      <Button
        onClick={() => {
          navigate("upload", {
            state: { mode: "course" },
          });
        }}
      >
        Upload new video
      </Button>
      {coursesData
        .filter((c) => c.videos)
        .map((course) => {
          return (
            <TableContainer mt={10} key={course.courseID}>
              <Heading mb={2} size="lg" as="h2">
                {course.courseID}
              </Heading>
              <Table>
                <Thead>
                  <Tr>
                    <Td>Video title</Td>
                    <Td>Status</Td>
                    <Td>Trailer Status</Td>
                    <Td>Actions</Td>
                  </Tr>
                </Thead>
                <Tbody>
                  {course.videos?.map((video) => {
                    const trailer = course.trailerVideos?.find(
                      (v) => v.videoNum === video.videoNum
                    );
                    if (trailer) {
                      video = {
                        ...video,
                        trailerUrl: trailer.embedUrl,
                        trailerLocked: trailer.locked,
                      };
                    }
                    return (
                      <Tr key={video.videoNum}>
                        <Td>{video.title}</Td>
                        <Td>{video.locked ? "Locked" : "Available"}</Td>
                        <Td>
                          {video.trailerUrl
                            ? video.trailerLocked
                              ? "Locked"
                              : "Available"
                            : "Not uploaded"}
                        </Td>
                        <Td>
                          <HStack>
                            <Button
                              size="sm"
                              onClick={() => {
                                navigate("courses/" + course.courseID, {
                                  state: {
                                    course,
                                    video,
                                  },
                                });
                              }}
                            >
                              Manage
                            </Button>
                            {!trailer?.embedUrl && (
                              <Button
                                size="sm"
                                onClick={() => {
                                  navigate("upload", {
                                    state: {
                                      mode: "trailer",
                                      course,
                                      video,
                                    },
                                  });
                                }}
                              >
                                Upload trailer
                              </Button>
                            )}
                          </HStack>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          );
        })}
    </Container>
  );
};

export default ManageCourses;
