import { createContext } from "react";
import { type User } from "@auth0/auth0-react";

export const AuthContext = createContext<{
  user: User | null | undefined;
  roles: string[];
  permissions: string[];
}>({
  user: null,
  roles: [],
  permissions: [],
});
