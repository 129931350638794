import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Box,
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<any>(null);

  if (!products) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/finances/stripe/products"}
        authScope="read:subscriptions"
        pageData={products}
        setData={setProducts}
      />
    );
  }
  const transformedProducts = products
    .sort((a: { active: boolean }) => {
      return a.active ? -1 : 1;
    })
    .map((p: any) => ({
      id: p.id,
      name: p.name === "Standard" ? "Basic" : p.name,
      type: p.type,
      active: p.active,
      created: new Date(p.created * 1000).toLocaleDateString(),
      updated: new Date(p.updated * 1000).toLocaleDateString(),
    }));

  return (
    <Box px={10}>
      <Heading>Products</Heading>
      <TableContainer my={10}>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Type</Th>
              <Th>Status</Th>
              <Th>Created</Th>
              <Th>Updated</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transformedProducts.map((p: any, i: number) => (
              <Tr key={i} backgroundColor={p.active ? "inherit" : "gray"}>
                <Td>
                  <Button
                    onClick={() => {
                      navigate({ pathname: p.id, search: "?pName=" + p.name });
                    }}
                  >
                    {p.name}
                  </Button>
                </Td>
                <Td>{p.type}</Td>
                <Td>{p.active ? "active" : "archived"}</Td>
                <Td>{p.created}</Td>
                <Td>{p.updated}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Products;
