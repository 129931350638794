import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { AuthContext } from "./context";

const AuthContextProvider = ({ children }: { children: any }) => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  useEffect(() => {
    async function getToken() {
      return await getAccessTokenSilently({
        authorizationParams: {
          audience: "DC-AD-API",
        },
      });
    }

    if (isAuthenticated) {
      getToken()
        .then((token) => {
          const decoded: any = jwt_decode(token);
          if (decoded) {
            if (decoded.permissions) {
              setPermissions(decoded.permissions);
            }
            if (decoded["DC-AD-API/roles"]) {
              setRoles(decoded["DC-AD-API/roles"]);
            }
          }
        })
        .catch((e) => console.error(e));
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <AuthContext.Provider value={{ user, permissions, roles }}>
      {children}
    </AuthContext.Provider>
  );
};

const ContextProvider = ({ children }: { children: any }) => {
  return (
    <>
      <AuthContextProvider>{children}</AuthContextProvider>
    </>
  );
};

export default ContextProvider;
