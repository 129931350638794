import {
  Box,
  Container,
  Heading,
  List,
  ListItem,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { type FC, useContext } from "react";
import { AuthContext } from "lib/context";

const AdminUserProfilePage: FC = () => {
  const { user, roles, permissions } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  const PermissionsTable = () => {
    if (permissions.length === 0) {
      return <></>;
    }
    const readPermissions = permissions
      .filter((permission) => permission.startsWith("read:"))
      .map((permission) => permission.replace("read:", ""));
    const writePermissions = permissions
      .filter((permission) => permission.startsWith("write:"))
      .map((permission) => permission.replace("write:", ""));
    return (
      <Box mt={10}>
        <Heading as="h2" size="lg">
          Your permissions
        </Heading>
        <SimpleGrid columns={2} spacing={10}>
          <Box>
            <Text as="u" fontSize="2xl">
              read
            </Text>
            <List>
              {readPermissions.map((permission) => (
                <ListItem key={permission}>{permission}</ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <Text as="u" fontSize="2xl">
              write
            </Text>
            <List>
              {writePermissions.map((permission) => (
                <ListItem key={permission}>{permission}</ListItem>
              ))}
            </List>
          </Box>
        </SimpleGrid>
      </Box>
    );
  };

  return (
    <Container centerContent>
      <Heading as="h1">Hello {user.name}!</Heading>
      {roles.length > 0 && (
        <Box mt={10}>
          <Heading as="h2" size="lg">
            Your roles
          </Heading>
          <List>
            {roles.map((permission) => (
              <ListItem key={permission}>{permission}</ListItem>
            ))}
          </List>
        </Box>
      )}
      {permissions.length > 0 && <PermissionsTable />}
    </Container>
  );
};

export default AdminUserProfilePage;
