import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Button,
  Container,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";

const Discounts = () => {
  const navigate = useNavigate();
  const [discountsData, setDiscountsData] = useState<any>(null);

  if (!discountsData) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/discounts/all"
        authScope="read:discounts"
        pageData={discountsData}
        setData={setDiscountsData}
      />
    );
  }
  return (
    <Container maxW="container.lg">
      <Heading as="h1" mb={10}>
        Discount codes
      </Heading>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Td>Name</Td>
              <Td>Remaining coupons</Td>
              <Td>Actions</Td>
            </Tr>
          </Thead>
          <Tbody>
            {discountsData.map((discount: any) => (
              <Tr key={discount.id}>
                <Td>{discount.short_name}</Td>
                <Td>{discount.remaining_amount}</Td>
                <Td>
                  <Button
                    onClick={() => {
                      navigate("details", { state: discount });
                    }}
                  >
                    Details
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Discounts;
