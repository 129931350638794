import { Route } from "customTypes/appTypes";
import ProtectedRoute from "router/ProtectedRoute";
import Events from "pages/Events/Events";
import EventList from "pages/Events/EventList";
import CreateEditEvent from "pages/Events/CreateEditEvent";
import EventDetails from "pages/Events/EventDetails";
import EventMessages from "pages/Events/EventMessages";

const eventRoutes: Route[] = [
  {
    name: "Event details",
    route: "/events/details",
    element: <ProtectedRoute component={EventDetails} />,
    permission: "read:events",
  },
  {
    name: "Event list",
    route: "/events/list",
    element: <ProtectedRoute component={EventList} />,
    permission: "read:events",
  },
  {
    name: "Create event",
    route: "/events/create",
    element: <ProtectedRoute component={CreateEditEvent} />,
    permission: "write:events",
  },
  {
    name: "Edit event",
    route: "/events/edit",
    element: <ProtectedRoute component={CreateEditEvent} />,
    permission: "write:events",
  },
  {
    name: "User messages",
    route: "/events/messages",
    element: <ProtectedRoute component={EventMessages} />,
    permission: "read:events",
  },
  {
    name: "Events",
    route: "/events",
    element: <ProtectedRoute component={Events} />,
    navbar: true,
    permission: "read:events",
  },
];

export default eventRoutes;
