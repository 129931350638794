import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { upperCase } from "lodash";

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}) => {
  return (
    <Box mt={10} role="alert">
      <Heading>{upperCase("Something went wrong")}</Heading>
      <Text>Uncaught Error:</Text>
      <pre>{error.message}</pre>
      <Button mt={3} onClick={resetErrorBoundary}>
        Try again
      </Button>
    </Box>
  );
};

export default ErrorFallback;
