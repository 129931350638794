import { useEffect, useRef, useState } from "react";
import { Avatar, Flex, Image, Text } from "@chakra-ui/react";
import { generateRandomAvatarUrl } from "lib/messages";

const MessageSection = ({
  chatData,
}: {
  chatData: { messages: any[]; userData: any };
}) => {
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!avatarUrl) {
      if (chatData.userData?.profImage) {
        setAvatarUrl(chatData.userData.profImage);
      } else {
        setAvatarUrl(generateRandomAvatarUrl());
      }
    }
  }, [avatarUrl, chatData.userData?.profImage]);
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<any>();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  if (!chatData?.messages) {
    return <>NOT FOUND</>;
  }

  const MessageBox = ({ item }: { item: any }) => {
    const fileExt = item?.fileName?.split(".").pop();
    switch (item.type) {
      case "text":
        return <Text>{item.message}</Text>;
      case "image":
        return (
          <Image
            onClick={() => {
              window.open(item.imageUrl, "_blank", "noreferrer");
            }}
            src={item.imageUrl}
            alt="image sent by user"
            style={{ width: "100%", cursor: "pointer" }}
          />
        );
      case "file":
        return (
          <a rel="noreferrer" target="_blank" href={item.fileUrl}>
            <Text style={{ color: "blue" }}>
              {item.fileName.substring(0, Math.min(15, item.fileName.length))}
              &#8230;.{fileExt}
            </Text>
            <br />
            <span>(opens in new tab, or downloads)</span>
          </a>
        );
      default:
        return (
          <Text color="red" fontWeight="bold">
            Unknown message type
          </Text>
        );
    }
  };

  return (
    <Flex w="100%" h="80%" overflowY="scroll" flexDirection="column" p="3">
      {chatData.messages.map((item, index) => {
        if (!item.fromClient) {
          return (
            <Flex key={index} w="100%" justify="flex-end">
              <Flex
                bg="black"
                color="white"
                minW="100px"
                maxW="350px"
                my="1"
                p="3"
              >
                <Text>{item.message}</Text>
              </Flex>
            </Flex>
          );
        } else {
          return (
            <Flex key={index} w="100%">
              <Avatar name="Anonymous" src={avatarUrl} bg="blue.300"></Avatar>
              <Flex
                bg="gray.100"
                color="black"
                minW="100px"
                maxW="450px"
                my="1"
                p="3"
              >
                <MessageBox item={item} />
              </Flex>
            </Flex>
          );
        }
      })}
      <AlwaysScrollToBottom />
    </Flex>
  );
};

export default MessageSection;
