import { Box, Heading } from "@chakra-ui/react";

const NotFound = () => {
  return (
    <Box>
      <Heading>404 Not Found</Heading>
    </Box>
  );
};

export default NotFound;
