import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Box,
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Payouts = () => {
  const navigate = useNavigate();
  const [payouts, setPayouts] = useState<any>(null);

  if (!payouts) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/finances/stripe/payouts"
        authScope="read:business"
        pageData={payouts}
        setData={setPayouts}
      />
    );
  }

  return (
    <Box px={10}>
      <Heading>Payouts (Stripe)</Heading>
      <TableContainer my={10}>
        <Table>
          <Thead>
            <Tr>
              <Td>Created</Td>
              <Td>Arrival date</Td>
              <Td>Status</Td>
              <Td>Amount</Td>
              <Td>Actions</Td>
            </Tr>
          </Thead>
          <Tbody>
            {payouts.map((p: any, i: number) => (
              <Tr key={i}>
                <Td>{new Date(p.created * 1000).toLocaleDateString()}</Td>
                <Td>{new Date(p.arrival_date * 1000).toLocaleDateString()}</Td>
                <Td>{p.status}</Td>
                <Td>
                  {p.amount / 100} {p.currency.toUpperCase()}
                </Td>
                <Td>
                  <Button
                    onClick={() => {
                      navigate(p.id);
                    }}
                  >
                    Check details
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Payouts;
