import { Box, Container, Heading } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import AuthPageGetData from "components/common/AuthPageGetData";
import { useState } from "react";
// import { VideoHeatmapChart } from "components/ELearningCharts";

const ELearningVideoStats = () => {
  const { videoId } = useParams();
  const [bunnyStreamData, setBunnyStreamData] = useState<any>(null);

  if (!bunnyStreamData) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/videos/cdn/video/" + videoId}
        authScope="read:elearning"
        pageData={bunnyStreamData}
        setData={setBunnyStreamData}
      />
    );
  }

  const toTimeString = (totalSeconds: number) => {
    const totalMs = totalSeconds * 1000;
    return new Date(totalMs).toISOString().slice(11, 19);
  };

  return (
    <>
      <Container>
        <Heading marginY={5}>{bunnyStreamData?.videoData.title}</Heading>
        <Box>Framerate: {bunnyStreamData?.videoData.framerate} FPS</Box>
        <Box>
          Width: {bunnyStreamData?.videoData.width}, Height:{" "}
          {bunnyStreamData?.videoData.height}
        </Box>
        <Box>
          Available resolutions:{" "}
          {bunnyStreamData?.videoData.availableResolutions
            ?.split(",")
            .join(", ")}
        </Box>
        <Box>
          Length: {toTimeString(bunnyStreamData?.videoData.length)} (hh:mm:ss)
        </Box>
        <Box>
          Date uploaded:{" "}
          {new Date(
            bunnyStreamData?.videoData.dateUploaded
          ).toLocaleDateString()}
        </Box>
        <Box>Views: {bunnyStreamData?.videoData.views}</Box>
        <Box>
          Average watch time:{" "}
          {toTimeString(bunnyStreamData?.videoData.averageWatchTime)} (hh:mm:ss)
        </Box>
        <Box>
          Total watch time:{" "}
          {toTimeString(bunnyStreamData?.videoData.totalWatchTime)} (hh:mm:ss)
        </Box>
      </Container>
      {/*<VideoHeatmapChart heatmapData={bunnyStreamData.heatmapData.heatmap} />*/}
    </>
  );
};

export default ELearningVideoStats;
