// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import palette from "google-palette";
import { PieChart } from "components/common/Charts";

export const JobAdStatChart = ({ jobs }: { jobs: any }) => {
  const labels = [];
  const data = [];
  const jobsByType = jobs
    ?.map((d: { project_type: { en?: string } }) => {
      if (d.project_type?.en && d.project_type.en.length !== 0) {
        return d.project_type.en.trim();
      }
    })
    .flat()
    .filter((e: any) => !!e)
    .reduce((acc: { [x: string]: number }, curr: string | number) => {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});
  for (const [key, value] of Object.entries(jobsByType)) {
    labels.push(key);
    data.push(value);
  }
  const chartData = {
    labels,
    datasets: [
      {
        label: "# of jobs",
        data,
        backgroundColor: palette("tol-dv", data.length).map(
          (hex: string) => `#${hex}`
        ),
        borderWidth: 1,
      },
    ],
  };

  return <PieChart heading="Jobs by type" data={chartData} />;
};

export const JobOffersStatChart = ({
  jobs,
  offers,
}: {
  jobs: any[];
  offers: any[];
}) => {
  const data = [];
  const labels = [];
  const offersByType = offers
    .map((d: { tender: string }) => ({
      ...d,
      projectType: jobs.filter((j: { id: any }) => j.id === d.tender)[0]
        ?.project_type.en,
    }))
    .map((d) => {
      if (d.projectType && d.projectType.length !== 0) {
        return d.projectType.trim();
      }
    })
    .flat()
    .filter((e) => !!e)
    .reduce((acc, curr) => {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});
  for (const [key, value] of Object.entries(offersByType)) {
    labels.push(key);
    data.push(value);
  }
  const chartData = {
    labels,
    datasets: [
      {
        label: "# of jobs",
        data,
        backgroundColor: palette("tol-dv", data.length).map(
          (hex: string) => `#${hex}`
        ),
        borderWidth: 1,
      },
    ],
  };
  return <PieChart heading="Offers by type" data={chartData} />;
};
