import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Switch,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { FormEvent, useEffect, useState } from "react";
import PageLoading from "components/app/PageLoading";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { appConfig } from "config/constants";
import { useAuth0 } from "@auth0/auth0-react";

const ManageCourseVideo = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const [videoTitle, setVideoTitle] = useState<string>(state?.video?.title);
  const [videoLocked, setVideoLocked] = useState<boolean>(state?.video?.locked);
  const [hasTrailer] = useState<boolean>(!!state?.video?.trailerUrl);
  const [trailerLocked, setTrailerLocked] = useState<boolean>(
    state?.video?.trailerLocked
  );
  const [writeAccessToken, setWriteAccessToken] = useState<string>("");
  const pageContainerWidth = useBreakpointValue({
    base: "container.md",
    lg: "container.lg",
  });

  useEffect(() => {
    if (!state) {
      navigate("/e-learning/manage");
    }
  }, [navigate, state]);

  useEffect(() => {
    const getWriteAccessToken = async () => {
      return await getAccessTokenSilently({
        authorizationParams: {
          audience: "DC-AD-API",
          scope: "write:elearning",
        },
      });
    };
    getWriteAccessToken()
      .then((accessToken: string) => {
        setWriteAccessToken(accessToken);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [getAccessTokenSilently]);

  if (!state) {
    return <PageLoading />;
  }

  const updateData = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (hasTrailer) {
      await fetch(appConfig.API_BASE_URL + "/videos/db/trailer/update", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${writeAccessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          trailerDocId: state.course?.trailerDocId,
          videoNum: state.video?.videoNum,
          locked: trailerLocked,
          title: videoTitle,
        }),
      });
    }
    const response = await fetch(
      appConfig.API_BASE_URL + "/videos/db/video/update",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${writeAccessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          courseDocId: state.course?.courseDocId,
          videoNum: state.video?.videoNum,
          locked: videoLocked,
          title: videoTitle,
        }),
      }
    );
    if (response.ok) {
      navigate("/e-learning/manage");
    }
  };

  return (
    <Container maxW={pageContainerWidth}>
      <Flex>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/e-learning/manage")}
        >
          Course list
        </Button>
        <Spacer />
        <Heading as="h1">
          Manage video no. {state.video.videoNum} of course &ldquo;
          {state.course.courseID}&rdquo;
        </Heading>
        <Spacer />
      </Flex>
      <Heading mt={5} size="md" as="h2">
        Video title: {state.video.title}
      </Heading>
      <Heading mt={20} size="md" as="h2">
        Update video data
      </Heading>
      <Container mt={5} maxW="sm">
        <form onSubmit={updateData}>
          <VStack spacing={4}>
            <FormControl isRequired={true}>
              <FormLabel>Video title</FormLabel>
              <Input
                value={videoTitle}
                onChange={(e) => {
                  setVideoTitle(e.target.value);
                }}
                placeholder="Add video title"
              />
            </FormControl>
            <FormControl>
              <Switch
                isChecked={!videoLocked}
                onChange={() => {
                  setVideoLocked(!videoLocked);
                }}
              >
                Is enabled
              </Switch>
            </FormControl>
            {hasTrailer && (
              <FormControl>
                <Switch
                  isChecked={!trailerLocked}
                  onChange={() => {
                    setTrailerLocked(!trailerLocked);
                  }}
                >
                  Is trailer enabled
                </Switch>
              </FormControl>
            )}
          </VStack>
          <Button mt={5} type="submit">
            Update data
          </Button>
        </form>
      </Container>
    </Container>
  );
};

export default ManageCourseVideo;
