import {
  Button,
  CircularProgress,
  Container,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ElearningVideoUploadBox } from "components/ELearning/FileUpload";
import { useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { createVideoBunny, uploadVideoDataToDB } from "lib/elearning";

const ELearningUploadForm = ({
  videoTitle,
  videoNum,
  trailerCollectionGUID,
  courseID,
  writeAccessToken,
}: {
  videoTitle: string;
  videoNum: number;
  trailerCollectionGUID: string;
  courseID: string;
  writeAccessToken: string;
}) => {
  const navigate = useNavigate();
  const [bunnyNewVideoData, setBunnyNewVideoData] = useState<{
    videoLibraryId: number;
    collectionId: string;
    guid: string;
    expirationTime: number;
    authSignature: string;
  }>({
    videoLibraryId: 0,
    collectionId: "",
    guid: "",
    expirationTime: 0,
    authSignature: "",
  });
  const [videoCreated, setVideoCreated] = useState<boolean>(false);
  const [videoUploaded, setVideoUploaded] = useState<boolean>(false);
  const [videoDataUploadError, setVideoDataUploadError] =
    useState<boolean>(false);
  const [videoDataUploaded, setVideoDataUploaded] = useState<boolean>(false);

  useEffect(() => {
    if (
      videoUploaded &&
      !videoDataUploaded &&
      courseID !== "" &&
      bunnyNewVideoData.guid !== "" &&
      bunnyNewVideoData.videoLibraryId !== 0
    ) {
      uploadVideoDataToDB(
        writeAccessToken,
        courseID,
        bunnyNewVideoData.videoLibraryId,
        bunnyNewVideoData.guid,
        videoTitle,
        true,
        videoNum
      ).then((res) => {
        if (res.ok) {
          setVideoDataUploaded(true);
        } else {
          setVideoDataUploadError(true);
        }
      });
    }
  }, [
    bunnyNewVideoData.guid,
    bunnyNewVideoData.videoLibraryId,
    videoTitle,
    courseID,
    videoDataUploaded,
    videoUploaded,
    writeAccessToken,
    videoNum,
  ]);

  const handleCreateVideo = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await createVideoBunny(
      trailerCollectionGUID,
      videoTitle,
      writeAccessToken,
      setBunnyNewVideoData,
      setVideoCreated
    );
  };

  return (
    <>
      {videoUploaded ? (
        <Container>
          <VStack spacing={3}>
            <Text>
              Video upload started successfully and is being processed. This can
              take a few minutes.
            </Text>
            {videoDataUploaded ? (
              <Button
                colorScheme="blue"
                onClick={() => {
                  navigate("/e-learning");
                }}
              >
                Return to the e-learning menu
              </Button>
            ) : videoDataUploadError ? (
              <Text color="red">
                An error happened uploading the video data. If you think this is
                temporary, please try again later. If you think this is a
                permanent error, please contact the administrator.
              </Text>
            ) : (
              <CircularProgress isIndeterminate color="blue.300" />
            )}
          </VStack>
        </Container>
      ) : videoCreated ? (
        <ElearningVideoUploadBox
          bunnyNewVideoData={bunnyNewVideoData}
          setVideoUploaded={setVideoUploaded}
        />
      ) : (
        <Container maxW="sm">
          <form onSubmit={handleCreateVideo}>
            <Text>
              Video you are creating a trailer for:
              <br />
              &ldquo;{videoTitle}&rdquo;
            </Text>
            <Button mt={5} type="submit">
              Create Video Placeholder
            </Button>
          </form>
        </Container>
      )}
    </>
  );
};

export default ELearningUploadForm;
