import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ElearningVideoUploadBox } from "components/ELearning/FileUpload";
import { useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { createVideoBunny, uploadVideoDataToDB } from "lib/elearning";

const ELearningUploadForm = ({
  collections,
  writeAccessToken,
}: {
  collections: any;
  writeAccessToken: string;
}) => {
  const navigate = useNavigate();
  const [courseID, setCourseID] = useState<string>("");
  const [bunnyCollection, setBunnyCollection] = useState<string>("");
  const [bunnyVideoTitle, setBunnyVideoTitle] = useState<string>("");
  const [bunnyNewVideoData, setBunnyNewVideoData] = useState<{
    videoLibraryId: number;
    collectionId: string;
    guid: string;
    expirationTime: number;
    authSignature: string;
  }>({
    videoLibraryId: 0,
    collectionId: "",
    guid: "",
    expirationTime: 0,
    authSignature: "",
  });
  const [videoCreated, setVideoCreated] = useState<boolean>(false);
  const [videoUploaded, setVideoUploaded] = useState<boolean>(false);
  const [videoDataUploadError, setVideoDataUploadError] =
    useState<boolean>(false);
  const [videoDataUploaded, setVideoDataUploaded] = useState<boolean>(false);

  useEffect(() => {
    if (
      videoUploaded &&
      !videoDataUploaded &&
      courseID !== "" &&
      bunnyNewVideoData.guid !== "" &&
      bunnyNewVideoData.videoLibraryId !== 0
    ) {
      uploadVideoDataToDB(
        writeAccessToken,
        courseID,
        bunnyNewVideoData.videoLibraryId,
        bunnyNewVideoData.guid,
        bunnyVideoTitle
      ).then((res) => {
        if (res.ok) {
          setVideoDataUploaded(true);
        } else {
          setVideoDataUploadError(true);
        }
      });
    }
  }, [
    bunnyNewVideoData.guid,
    bunnyNewVideoData.videoLibraryId,
    bunnyVideoTitle,
    courseID,
    videoDataUploaded,
    videoUploaded,
    writeAccessToken,
  ]);

  const handleCreateVideo = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await createVideoBunny(
      bunnyCollection,
      bunnyVideoTitle,
      writeAccessToken,
      setBunnyNewVideoData,
      setVideoCreated
    );
  };

  return (
    <>
      {videoUploaded ? (
        <Container>
          <VStack spacing={3}>
            <Text>
              Video upload started successfully and is being processed. This can
              take a few minutes.
            </Text>
            {videoDataUploaded ? (
              <Button
                colorScheme="blue"
                onClick={() => {
                  navigate("/e-learning");
                }}
              >
                Return to the e-learning menu
              </Button>
            ) : videoDataUploadError ? (
              <Text color="red">
                An error happened uploading the video data. If you think this is
                temporary, please try again later. If you think this is a
                permanent error, please contact the administrator.
              </Text>
            ) : (
              <CircularProgress isIndeterminate color="blue.300" />
            )}
          </VStack>
        </Container>
      ) : videoCreated ? (
        <ElearningVideoUploadBox
          bunnyNewVideoData={bunnyNewVideoData}
          setVideoUploaded={setVideoUploaded}
        />
      ) : (
        <Container maxW="sm">
          <form onSubmit={handleCreateVideo}>
            <VStack spacing={4}>
              <FormControl isRequired={true}>
                <FormLabel>Course</FormLabel>
                <Select
                  value={bunnyCollection}
                  onChange={(e) => {
                    setCourseID(
                      collections.filter(
                        (collection: any) => collection.guid === e.target.value
                      )[0]?.name
                    );
                    setBunnyCollection(e.target.value);
                  }}
                >
                  <option hidden disabled value="">
                    Select a course
                  </option>
                  {collections.map((collection: any) => (
                    <option key={collection.guid} value={collection.guid}>
                      {collection.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired={true}>
                <FormLabel>Video title</FormLabel>
                <Input
                  value={bunnyVideoTitle}
                  onChange={(e) => {
                    setBunnyVideoTitle(e.target.value);
                  }}
                  placeholder="Add video title"
                />
              </FormControl>
            </VStack>
            <Button mt={10} type="submit">
              Create Video Placeholder
            </Button>
          </form>
        </Container>
      )}
    </>
  );
};

export default ELearningUploadForm;
