import { type Route } from "customTypes/appTypes";
import ProtectedRoute from "router/ProtectedRoute";
import ELearningVideoStats from "pages/ELearning/ELearningVideoStats";
import ELeaningSummary from "pages/ELearning/ELeaningSummary";
import ManageCourseVideo from "pages/ELearning/ManageCourseVideo";
import ELearningUpload from "pages/ELearning/ELearningUpload";
import ManageCourses from "pages/ELearning/ManageCourses";
import ELearning from "pages/ELearning/ELearning";

const elearningRoutes: Route[] = [
  {
    name: "e-Learning video statistics",
    route: "/e-learning/stats/:videoId",
    element: <ProtectedRoute component={ELearningVideoStats} />,
    permission: "read:elearning",
  },
  {
    name: "e-Learning video statistics",
    route: "/e-learning/stats",
    element: <ProtectedRoute component={ELeaningSummary} />,
    permission: "read:elearning",
  },
  {
    name: "e-Learning manage course video",
    route: "/e-learning/manage/courses/:courseID",
    element: <ProtectedRoute component={ManageCourseVideo} />,
    permission: "write:elearning",
  },
  {
    name: "e-Learning video upload",
    route: "/e-learning/manage/upload",
    element: <ProtectedRoute component={ELearningUpload} />,
    permission: "write:elearning",
  },
  {
    name: "e-Learning courses",
    route: "/e-learning/manage",
    element: <ProtectedRoute component={ManageCourses} />,
    permission: "read:elearning",
  },
  {
    name: "e-Learning",
    route: "/e-learning",
    element: <ProtectedRoute component={ELearning} />,
    navbar: true,
    permission: "read:elearning",
  },
];

export default elearningRoutes;
