import { Button, Container, Heading, SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "lib/context";

const Events = () => {
  const navigate = useNavigate();
  const { permissions } = useContext(AuthContext);
  return (
    <Container maxW="container.xl" centerContent>
      <Heading>Events for DroneCakes users</Heading>
      <SimpleGrid mt={5} columns={2} spacing={3}>
        <Button
          onClick={() => {
            navigate("list");
          }}
        >
          List events
        </Button>
        {permissions.includes("write:events") && (
          <Button
            onClick={() => {
              navigate("create");
            }}
          >
            Create event
          </Button>
        )}
        <Button
          onClick={() => {
            navigate("messages");
          }}
        >
          User messages
        </Button>
      </SimpleGrid>
    </Container>
  );
};

export default Events;
