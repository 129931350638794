import { useAuth0 } from "@auth0/auth0-react";

const AuthCallback = () => {
  const { error } = useAuth0();
  if (error) {
    return <div>Oops... {error.message} </div>;
  }
  return <div>Redirecting...</div>;
};

export default AuthCallback;
