import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "components/app/NavBar";
import NotFound from "pages/app/NotFound";
import AuthCallback from "pages/app/AuthCallback";
import routes from "./routes";

const RouterWithAuthProtection = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        {routes
          .filter((route) => route.element)
          .map((route) => (
            <Route
              key={route.route}
              path={route.route}
              element={route.element}
            />
          ))}
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterWithAuthProtection;
