import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import { JobAdStatChart, JobOffersStatChart } from "components/Jobs/JobCharts";
import { Container, Heading, SimpleGrid } from "@chakra-ui/react";

const JobStats = () => {
  const [jobsData, setJobsData] = useState<any>(null);

  if (!jobsData) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/jobs/all"
        authScope="read:jobs"
        pageData={jobsData}
        setData={setJobsData}
      />
    );
  }
  return (
    <Container centerContent px={3}>
      <Heading as="h1" size="xl" mb={10}>
        Jobs and offers statistics
      </Heading>
      <SimpleGrid columns={2} spacing={10}>
        <JobAdStatChart
          jobs={jobsData?.jobs.filter((j: any) => j.project_type)}
        />
        <JobOffersStatChart
          jobs={jobsData?.jobs.filter((j: any) => j.project_type)}
          offers={jobsData?.offers}
        />
      </SimpleGrid>
    </Container>
  );
};

export default JobStats;
