import { Container, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AuthPageGetData from "components/common/AuthPageGetData";
import ELearningUploadForm from "components/ELearning/ELearningUploadForm";
import { useLocation, useNavigate } from "react-router-dom";
import ELearningUploadTrailerForm from "components/ELearning/ELearningUploadTrailerForm";

const ELearningUpload = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const [bunnyStreamData, setBunnyStreamData] = useState<any>(null);
  const [writeAccessToken, setWriteAccessToken] = useState<string>("");

  useEffect(() => {
    if (!state || (state && state.mode === "trailer" && !state.video?.title)) {
      navigate("/e-learning/manage");
    }
  }, [navigate, state]);

  useEffect(() => {
    const getWriteAccessToken = async () => {
      return await getAccessTokenSilently({
        authorizationParams: {
          audience: "DC-AD-API",
          scope: "write:elearning",
        },
      });
    };
    getWriteAccessToken()
      .then((accessToken: string) => {
        setWriteAccessToken(accessToken);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [getAccessTokenSilently]);

  if (!bunnyStreamData) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/videos/cdn/all"
        authScope="read:elearning"
        pageData={bunnyStreamData}
        setData={setBunnyStreamData}
      />
    );
  }

  if (!bunnyStreamData.collectionData?.items) {
    return (
      <Heading color="red">Could not load data from Bunny servers</Heading>
    );
  }

  const collections = bunnyStreamData.collectionData.items;
  const collectionsToList = collections.filter(
    (c: { name: string }) =>
      ![
        process.env.NODE_ENV === "development" ? "" : "test",
        "trailers",
      ].includes(c.name)
  );
  const trailerCollectionGUID = collections.find(
    (c: { name: string }) => c.name === "trailers"
  )?.guid;

  return (
    <Container>
      <Heading mb={10} as="h1">
        Upload {state.mode === "trailer" && "trailer for"} E-learning Video
      </Heading>
      {state.mode === "course" ? (
        <ELearningUploadForm
          collections={collectionsToList}
          writeAccessToken={writeAccessToken}
        />
      ) : (
        <ELearningUploadTrailerForm
          videoTitle={state.video.title}
          videoNum={state.video.videoNum}
          trailerCollectionGUID={trailerCollectionGUID}
          courseID={state.course.courseID}
          writeAccessToken={writeAccessToken}
        />
      )}
    </Container>
  );
};

export default ELearningUpload;
