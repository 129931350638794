import { useContext, useEffect, useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { chain } from "lodash";
import { AuthContext } from "lib/context";
import { useNavigate } from "react-router-dom";

const Invoices = () => {
  const navigate = useNavigate();
  const { roles } = useContext(AuthContext);
  const [invoices, setInvoices] = useState<any>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string>("");
  const [selectedInvoices, setSelectedInvoices] = useState<{
    customerName: string;
    invoices: any;
  }>();

  useEffect(() => {
    if (!roles.includes("Stripe")) {
      navigate("/finances");
    }
  }, [navigate, roles]);

  if (!invoices) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/finances/stripe/invoices"
        authScope="read:business"
        pageData={invoices}
        setData={setInvoices}
      />
    );
  }

  const groupedInvoices = chain(invoices)
    .groupBy("customer_name")
    .map((value, key) => ({ customerName: key, invoices: value }))
    .value();

  const handleSelectCustomer = (selected: any) => {
    setSelectedCustomer(selected.item.value);
    setSelectedInvoices(
      groupedInvoices.find((i: any) => i.customerName === selected.item.value)
    );
  };

  return (
    <Container maxW="container.xl" centerContent>
      <Heading as="h1">Search customer invoices</Heading>
      <Flex mt={20} justify="center" align="center" w="full">
        <FormControl minW={180} maxW={300}>
          <FormLabel>Search invoices</FormLabel>
          <AutoComplete
            onSelectOption={handleSelectCustomer}
            emptyState={<Text>No customer found</Text>}
          >
            <AutoCompleteInput />
            <AutoCompleteList>
              {groupedInvoices?.map((invoice: any) => (
                <AutoCompleteItem
                  key={`option-${invoice.customerName}`}
                  value={invoice.customerName}
                >
                  {invoice.customerName}
                </AutoCompleteItem>
              ))}
            </AutoCompleteList>
          </AutoComplete>
          <FormHelperText>Search name of customer.</FormHelperText>
        </FormControl>
      </Flex>
      {selectedCustomer && (
        <>
          <Heading mt={20} as="h2" size="md">
            Invoices for {selectedCustomer}
          </Heading>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Td>Number</Td>
                  <Td>Created</Td>
                  <Td>Billing reason</Td>
                  <Td>Status</Td>
                  <Td>Total</Td>
                </Tr>
              </Thead>
              <Tbody>
                {selectedInvoices?.invoices.map((invoice: any) => (
                  <Tr key={invoice.id}>
                    <Td>{invoice.number}</Td>
                    <Td>
                      {new Date(invoice.created * 1000).toLocaleDateString()}
                    </Td>
                    <Td>{invoice.billing_reason.split("_").join(" ")}</Td>
                    <Td>{invoice.status}</Td>
                    <Td>
                      {invoice.total / 100} {invoice.currency.toUpperCase()}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
  );
};

export default Invoices;
