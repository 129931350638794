import {
  Box,
  Button,
  Container,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthPageGetData from "components/common/AuthPageGetData";

const ELeaningSummary = () => {
  const navigate = useNavigate();
  const [bunnyStreamData, setBunnyStreamData] = useState<any>(null);

  if (!bunnyStreamData) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/videos/cdn/all"
        authScope="read:elearning"
        pageData={bunnyStreamData}
        setData={setBunnyStreamData}
      />
    );
  }
  // sort by title
  bunnyStreamData.videoData.items.sort(
    (a: { title: string }, b: { title: string }) => {
      return a.title.localeCompare(b.title);
    }
  );

  const VideosTable = ({ collection }: { collection: any }) => {
    if (!collection || collection.videoCount === 0) {
      return (
        <Container>
          <Heading my={2} size="lg" as="h2">
            {collection.name}
          </Heading>
          <Text>No videos in this collection</Text>
        </Container>
      );
    }
    const TableHeader = () => {
      return (
        <Thead>
          <Tr>
            <Th>Video Title</Th>
            <Th>Views</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
      );
    };
    return (
      <TableContainer my={10}>
        <Heading my={2} size="lg" as="h2">
          {collection.name}
        </Heading>
        <Table variant="striped" colorScheme="teal" size="sm">
          <TableHeader />
          <Tbody>
            {bunnyStreamData.videoData.items?.map((video: any, i: number) => {
              if (video.collectionId === collection.guid) {
                return (
                  <Tr key={i}>
                    <Td>{video.title}</Td>
                    <Td>{video.views}</Td>
                    <Td>
                      <Button
                        onClick={() => {
                          navigate(video.guid);
                        }}
                      >
                        View details
                      </Button>
                    </Td>
                  </Tr>
                );
              }
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container>
      <Heading>E-Leaning Statistics</Heading>
      <Box marginY={10}>
        {bunnyStreamData.collectionData.items
          ?.sort((a: { name: string }, b: { name: string }) => {
            return a.name.localeCompare(b.name);
          })
          .map((collection: any, i: number) => {
            return <VideosTable key={i} collection={collection} />;
          })}
      </Box>
    </Container>
  );
};

export default ELeaningSummary;
