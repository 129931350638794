import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FC } from "react";
import { Spinner } from "@chakra-ui/react";

type ProtectedRouteProps = {
  component: FC;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Spinner />,
  });

  return <Component />;
};

export default ProtectedRoute;
