import { type Route } from "customTypes/appTypes";
import Home from "pages/Home";
import UserStats from "pages/Users/UserStats";
import ProtectedRoute from "./ProtectedRoute";
import AdminUserProfilePage from "pages/app/AdminUserProfilePage";
import JobStats from "pages/JobStats";
import Discounts from "pages/Discounts/Discounts";
import Chat from "pages/Chat/Chat";
import Messages from "pages/Chat/Messages";
import UserDetails from "pages/Users/UserDetails";
import DiscountDetails from "pages/Discounts/DiscountDetails";
import elearningRoutes from "router/elearning.routes";
import financeRoutes from "router/finance.routes";
import eventRoutes from "router/event.routes";
import Users from "pages/Users/Users";
import LatestUsers from "pages/Users/LatestUsers";

const routes: Route[] = [
  {
    name: "Home",
    route: "/",
    element: <Home />,
    navbar: true,
  },
  ...elearningRoutes,
  ...financeRoutes,
  {
    name: "User Details",
    route: "/users/user/:userId",
    element: <ProtectedRoute component={UserDetails} />,
    permission: "read:users",
  },
  {
    name: "Latest users",
    route: "/users/list",
    element: <ProtectedRoute component={LatestUsers} />,
    permission: "read:users",
  },
  {
    name: "User Stats",
    route: "/users/stats",
    element: <ProtectedRoute component={UserStats} />,
    permission: "read:users",
  },
  {
    name: "Users",
    route: "/users",
    element: <ProtectedRoute component={Users} />,
    navbar: true,
    permission: "read:users",
  },
  {
    name: "Chat message flow",
    route: "/chat/:chatId/messages",
    element: <ProtectedRoute component={Messages} />,
    permission: "read:chat",
  },
  {
    name: "Messages",
    route: "/chat",
    element: <ProtectedRoute component={Chat} />,
    navbar: true,
    permission: "read:chat",
  },
  {
    name: "Jobs",
    route: "/jobs/stats",
    element: <ProtectedRoute component={JobStats} />,
    navbar: true,
    permission: "read:jobs",
  },
  {
    name: "Discount Details",
    route: "/discounts/details",
    element: <ProtectedRoute component={DiscountDetails} />,
    permission: "read:discounts",
  },
  {
    name: "Discounts",
    route: "/discounts",
    element: <ProtectedRoute component={Discounts} />,
    navbar: true,
    permission: "read:discounts",
  },
  ...eventRoutes,
  {
    name: "Profile",
    element: <ProtectedRoute component={AdminUserProfilePage} />,
    route: "/auth/profile",
  },
];
export default routes;
