import { Button, Container, Heading, SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ELearning = () => {
  const navigate = useNavigate();
  return (
    <Container centerContent>
      <Heading>ELearning</Heading>
      <SimpleGrid mt={5} columns={2} spacing={3}>
        <Button
          onClick={() => {
            navigate("manage");
          }}
        >
          Manage videos
        </Button>
        <Button
          onClick={() => {
            navigate("stats");
          }}
        >
          Stats
        </Button>
      </SimpleGrid>
    </Container>
  );
};

export default ELearning;
