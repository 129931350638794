import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Tus from "@uppy/tus";
import { Dispatch } from "react";

const ElearningVideoUploadBox = ({
  bunnyNewVideoData,
  setVideoUploaded,
  acceptedFileTypes = ["video/*"],
}: {
  bunnyNewVideoData: {
    videoLibraryId: number;
    collectionId: string;
    guid: string;
    expirationTime: number;
    authSignature: string;
  };
  setVideoUploaded: Dispatch<boolean>;
  acceptedFileTypes?: string[];
}) => {
  const uppy = new Uppy({
    id: "uppy-dashboard-video",
    autoProceed: false,
    allowMultipleUploadBatches: false,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: acceptedFileTypes,
    },
  });

  uppy
    .use(Tus, {
      endpoint: "https://video.bunnycdn.com/tusupload",
      headers: {
        AuthorizationSignature: bunnyNewVideoData.authSignature,
        AuthorizationExpire: bunnyNewVideoData.expirationTime.toString(),
        VideoId: bunnyNewVideoData.guid,
        LibraryId: bunnyNewVideoData.videoLibraryId.toString(),
      },
      limit: 1,
    })
    .on("upload-success", (file, response) => {
      setVideoUploaded(true);
    });

  return (
    <Dashboard uppy={uppy} proudlyDisplayPoweredByUppy={false} height={400} />
  );
};

export { ElearningVideoUploadBox };
