import { useState } from "react";
import {
  Button,
  Container,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import AuthPageGetData from "components/common/AuthPageGetData";
import { EventMessage } from "customTypes/otherTypes";
import { useLocation } from "react-router-dom";
import exportFromJSON from "export-from-json";

const EventMessages = () => {
  const { state } = useLocation();
  const [messages, setMessages] = useState<EventMessage[] | null>(null);

  if (!messages) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/events/messages"
        authScope="read:events"
        pageData={messages}
        setData={setMessages}
      />
    );
  }

  const newDateRequests = messages.filter(
    (message) => message.event && message.date
  );
  const eventSuggestions = messages.filter(
    (message) => message.eventName && message.comment
  );

  const downloadMessages = (suggestions = true) => {
    let fileName = suggestions ? "event-suggestions" : "new-date-requests";
    fileName = `${fileName}-${new Date().toISOString().split("T")[0]}`;
    const data = suggestions
      ? eventSuggestions.map((message) => ({
          "event idea": message.eventName,
          comment: message.comment,
        }))
      : newDateRequests.map((message) => ({
          "event name": message.eventName,
          request: message.date,
        }));
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <Container maxW="container.lg">
      <Heading my={5} as="h1">
        User messages about events
      </Heading>
      <Heading mt={12} as="h2" fontSize={20}>
        Existing events with new date requests
      </Heading>
      {newDateRequests.length > 0 && (
        <Button
          color="white"
          bg="blue.500"
          _hover={{ bg: "blue.600" }}
          mt={2}
          onClick={() => downloadMessages(false)}
        >
          Download messages
        </Button>
      )}
      {newDateRequests.length > 0 ? (
        <TableContainer mt={5}>
          <Table>
            <Thead>
              <Tr>
                <Td>Event name</Td>
                <Td>Event date</Td>
              </Tr>
            </Thead>
            <Tbody>
              {newDateRequests.map((message) => (
                <Tr key={message.uid}>
                  <Td>{message.eventName}</Td>
                  <Td>{message.date}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text color="gray.500" mt={3}>
          No new date requests
        </Text>
      )}
      <Heading mt={12} as="h2" fontSize={20}>
        Event suggestions
      </Heading>
      {eventSuggestions.length > 0 && (
        <Button
          color="white"
          bg="blue.500"
          _hover={{ bg: "blue.600" }}
          mt={2}
          onClick={() => downloadMessages()}
        >
          Download messages
        </Button>
      )}
      {eventSuggestions.length > 0 ? (
        <TableContainer mt={5} mb={10}>
          <Table>
            <Thead>
              <Tr>
                <Td>Event name</Td>
                <Td>Event comment</Td>
              </Tr>
            </Thead>
            <Tbody>
              {eventSuggestions.map((message) => (
                <Tr key={message.uid}>
                  <Td>{message.eventName}</Td>
                  <Td>{message.comment}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text color="gray.500" mt={3}>
          No event suggestions
        </Text>
      )}
    </Container>
  );
};

export default EventMessages;
