import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Spacer,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { AuthContext } from "lib/context";

const Payments = () => {
  const navigate = useNavigate();
  const { roles } = useContext(AuthContext);
  const { paymentId } = useParams();
  const [paymentDetails, setPaymentDetails] = useState<any>(null);

  if (!paymentDetails) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/finances/stripe/payments/" + paymentId}
        authScope="read:subscriptions"
        pageData={paymentDetails}
        setData={setPaymentDetails}
      />
    );
  }

  console.log(paymentDetails);

  return (
    <Container>
      <Flex>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/subscriptions/payments")}
        >
          Payment list
        </Button>
        <Spacer />
        <Heading as="h1">Payment details</Heading>
        <Spacer />
      </Flex>
      <VStack
        mt={10}
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
      >
        {roles.includes("Stripe") && (
          <HStack spacing="24px">
            <Text>Details: </Text>
            <Button
              onClick={() => {
                window.open(
                  "https://dashboard.stripe.com" +
                    (process.env.NODE_ENV === "development" ? "/test" : "") +
                    "/payments/" +
                    paymentDetails.id,
                  "_blank",
                  "noreferrer"
                );
              }}
            >
              Payment
            </Button>
            {paymentDetails.customer && (
              <Button
                onClick={() => {
                  window.open(
                    "https://dashboard.stripe.com" +
                      (process.env.NODE_ENV === "development" ? "/test" : "") +
                      "/customers/" +
                      paymentDetails.customer,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                Customer
              </Button>
            )}
            {paymentDetails.invoice && (
              <Button
                onClick={() => {
                  window.open(
                    "https://dashboard.stripe.com" +
                      (process.env.NODE_ENV === "development" ? "/test" : "") +
                      "/invoices/" +
                      paymentDetails.invoice,
                    "_blank",
                    "noreferrer"
                  );
                }}
              >
                Invoice
              </Button>
            )}
          </HStack>
        )}
        <Text>Customer: {paymentDetails.customer?.name}</Text>
        <Text>
          Payment amount: {paymentDetails.amount / 100}{" "}
          {paymentDetails.currency.toUpperCase()}
        </Text>
        <Text>Payment status: {paymentDetails.status}</Text>
        {paymentDetails.status === "canceled" && (
          <Text>
            Payment cancellation reason:{" "}
            {paymentDetails.cancellation_reason.split("_").join(" ")}
          </Text>
        )}
        {paymentDetails.last_payment_error && (
          <>
            <Text>
              Last payment error:{" "}
              {paymentDetails.last_payment_error.code.split("_").join(" ")}
            </Text>
            <Text>Message: {paymentDetails.last_payment_error.message}</Text>
          </>
        )}
        <Text>
          Payment created:{" "}
          {new Date(paymentDetails.created * 1000).toLocaleDateString()}
        </Text>
      </VStack>
    </Container>
  );
};

export default Payments;
