import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

const PromoCodes = () => {
  const { couponId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const couponName = state?.cName;
  const [prCodeData, setPrCodeData] = useState<any>(null);

  useEffect(() => {
    if (!state) {
      navigate("/subscriptions/coupons");
    }
  }, [navigate, state]);

  if (!prCodeData) {
    return (
      <AuthPageGetData
        dataApiEndpoint={"/finances/stripe/coupons/" + couponId}
        authScope="read:subscriptions"
        pageData={prCodeData}
        setData={setPrCodeData}
      />
    );
  }

  if (prCodeData.length === 0) {
    return (
      <Box px={10}>
        <Flex>
          <Button
            leftIcon={<ArrowBackIcon />}
            onClick={() => navigate("/subscriptions/coupons")}
          >
            Product list
          </Button>
          <Spacer />
          <Heading>No promo codes found</Heading>
          <Spacer />
        </Flex>
      </Box>
    );
  }

  const transformedPrCodeData = prCodeData.map((p: any) => ({
    id: p.id,
    name: p.code,
    active: p.active,
    timesUsed: p.times_redeemed,
    maxRedemptions: p.max_redemptions ?? "N/A",
    minOrderValue: p.restrictions.minimum_amount
      ? p.restrictions.minimum_amount / 100 +
        " " +
        p.restrictions.minimum_amount_currency.toUpperCase()
      : "N/A",
  }));
  return (
    <Box px={10}>
      <Flex>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/subscriptions/coupons")}
        >
          Coupon list
        </Button>
        <Spacer />
        <Heading>
          Promo codes{couponName && ' for "' + couponName + '"'}
        </Heading>
        <Spacer />
      </Flex>
      <TableContainer mt={10}>
        <Table>
          <Thead>
            <Tr>
              <Th>Name (user facing)</Th>
              <Th>Status</Th>
              <Th>Times used</Th>
              <Th>Max redemptions</Th>
              <Th>Minimum order value</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transformedPrCodeData.map((c: any, i: number) => (
              <Tr key={i} backgroundColor={c.active ? "inherit" : "gray"}>
                <Td>{c.name}</Td>
                <Td>{c.active ? "active" : "archived"}</Td>
                <Td>{c.timesUsed}</Td>
                <Td>{c.maxRedemptions}</Td>
                <Td>{c.minOrderValue}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PromoCodes;
