import { Box, ChakraProvider, Spinner, theme } from "@chakra-ui/react";
import RouterWithAuthProtection from "./router";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/app/ErrorFallback";
import ContextProvider from "lib/ContextProvider";

export const App = () => {
  const { isLoading } = useAuth0();
  return (
    <ChakraProvider theme={theme}>
      {isLoading ? (
        <Box textAlign="center">
          Loading App <Spinner size="sm" />
        </Box>
      ) : (
        <Box textAlign="center" fontSize="xl">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ContextProvider>
              <RouterWithAuthProtection />
            </ContextProvider>
          </ErrorBoundary>
        </Box>
      )}
    </ChakraProvider>
  );
};
