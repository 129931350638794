import { useState } from "react";
import AuthPageGetData from "components/common/AuthPageGetData";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  BillingoTable,
  GcpTable,
  OtherTable,
  VercelTable,
} from "components/Finances/OpCosts/Tables";
import { useNavigate } from "react-router-dom";

const OperationCosts = () => {
  const navigate = useNavigate();
  const [operationCosts, setOperationCosts] = useState<any>(null);

  if (!operationCosts) {
    return (
      <AuthPageGetData
        dataApiEndpoint="/finances/operation/costs"
        authScope="read:business"
        pageData={operationCosts}
        setData={setOperationCosts}
      />
    );
  }

  return (
    <Box px={20}>
      <Heading as="h1">Operation Costs</Heading>
      <Grid mt={5} templateColumns="repeat(2,1fr)" gap={20}>
        <GridItem colSpan={2}>
          <OtherTable otherCosts={operationCosts.otherCosts} />
        </GridItem>
        <GridItem colSpan={2}>
          <VStack>
            <Text>
              Microsoft costs:{" "}
              <Button
                size="sm"
                onClick={() => {
                  navigate("microsoft");
                }}
              >
                Details
              </Button>
            </Text>
            <Spacer />
            <Text>
              Bunny CDN costs (e-learning):{" "}
              <Button
                size="sm"
                onClick={() => {
                  navigate("bunny");
                }}
              >
                Details
              </Button>
            </Text>
          </VStack>
        </GridItem>
        <GridItem rowSpan={2}>
          <VercelTable vercelCosts={operationCosts.vercelCosts} />
        </GridItem>
        <GridItem>
          <GcpTable gcpCosts={operationCosts.gcpCosts} />
        </GridItem>
        <GridItem>
          <BillingoTable billingoCosts={operationCosts.billingoCosts} />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default OperationCosts;
